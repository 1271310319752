import { CLEAR_REQUIRED, SET_REQUIRED } from "../Components/actions/actionTypes";

export default function(state=[],action){
    const {type,payload}=action;
    switch(type){
        case SET_REQUIRED:
            return payload
        case CLEAR_REQUIRED:
            return []
        default: return state
    }
}