import React, { useState, useEffect } from "react";
import { useHistory } from "react-router";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

// import '../../styles/new_css/bootstrap.min.css'
const Profile = () => {
  const { auth } = useSelector((state) => state.auth);
  const [profileData, setProfileData] = useState({
    email: "",
    firstName: "",
    lastName: "",
    phoneNo: "",
    companyName: "",
    location: "",
  });

  const history = useHistory();

  useEffect(() => {
    if (!auth.auth) {
      history.push("/");
    }
  }, []);

  useEffect(async () => {
    getUserProfile();
  }, []);

  async function getUserProfile() {
    const userId = auth.id;
    let item = {
      userId: userId,
    };
    let result = await fetch(
      process.env.REACT_APP_BACKEND_URL + "/user/userDetails",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "applicaton/json",
        },
        body: JSON.stringify(item),
      }
    );
    result = await result.json();
    if (result.status == true) {
      setProfileData((prev) => ({ ...prev, ...result.result }));
    }
  }

  async function profileUpdate() {
    // email = email.toLowerCase();
    const userId = auth.id;
    let item = {
      userId: userId,
      email: profileData.email,
      firstName: profileData.firstName,
      lastName: profileData.lastName,
      phoneNo: profileData.phoneNo,
      companyName: profileData.companyName,
      location: profileData.location,
    };
    let result = await fetch(
      process.env.REACT_APP_BACKEND_URL + "/user/updateProfile",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "applicaton/json",
        },
        body: JSON.stringify(item),
      }
    );
    result = await result.json();
    if (result.status == "true") {
      localStorage.setItem("firstName", result.response.data.firstName);
      localStorage.setItem("lastName", result.response.data.lastName);
      alert(result.message);
    } else {
      alert(result.message);
    }
  }

  return (
    <section className="profile">
      <div className="container align-items-center">
        <div className="row justify-content-center no-gutters align-items-center">
          <div className="col-md-7 col-lg-5">
            <div className="loginBox m-auto">
              <h1>
                <span>My profile</span>
              </h1>
              <form className="form-horizontal" action="" method="post">
                <fieldset>
                  <div className="row">
                    <div className="col-md-6">
                      <div className="form-group position-relative ">
                        <div className="iconImg">
                          <img src="img/user.png" />
                        </div>
                        <input
                          id="firstName"
                          name="firstName"
                          type="text"
                          placeholder="First Name"
                          value={profileData.firstName}
                          className="form-control"
                          onChange={(e) =>
                            setProfileData({
                              ...profileData,
                              firstName: e.target.value,
                            })
                          }
                        />
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-group position-relative">
                        <div className="iconImg">
                          <img src="img/user.png" />
                        </div>
                        <input
                          id="lastName"
                          name="lastName"
                          type="text"
                          placeholder="Last Name"
                          className="form-control"
                          value={profileData.lastName}
                          onChange={(e) =>
                            setProfileData({
                              ...profileData,
                              lastName: e.target.value,
                            })
                          }
                        />
                      </div>
                    </div>
                  </div>

                  <div className="form-group position-relative">
                    <div className="iconImg">
                      <img src="img/email.png" />
                    </div>
                    <input
                      id="email"
                      name="email"
                      type="text"
                      placeholder="Email"
                      className="form-control"
                      value={profileData.email}
                      readOnly
                    />
                  </div>
                  <div className="form-group position-relative">
                    <div className="iconImg">
                      <img src="img/call.png" />
                    </div>
                    <input
                      id="phoneNo"
                      name="phoneNo"
                      type="text"
                      placeholder="Phone Number*"
                      className="form-control"
                      value={profileData.phoneNo}
                      onChange={(e) =>
                        setProfileData({
                          ...profileData,
                          phoneNo: e.target.value,
                        })
                      }
                    />
                  </div>
                  <div className="form-group position-relative">
                    <div className="iconImg">
                      <img src="img/company.png" />
                    </div>
                    <input
                      id="companyName"
                      name="companyName"
                      type="text"
                      placeholder="Company/Farm Name"
                      value={profileData.companyName}
                      onChange={(e) =>
                        setProfileData({
                          ...profileData,
                          companyName: e.target.value,
                        })
                      }
                      className="form-control"
                    />
                  </div>
                  <div className="form-group position-relative">
                    <div className="iconImg">
                      <img src="img/map.png" />
                    </div>
                    <input
                      id="location"
                      name="location"
                      type="text"
                      placeholder="Location/Town"
                      value={profileData.location}
                      className="form-control"
                      onChange={(e) =>
                        setProfileData({
                          ...profileData,
                          location: e.target.value,
                        })
                      }
                    />
                  </div>
                  <div className="form-group mb-0 d-flex justify-content-between align-items-center subBtn">
                    <button
                      type="submit"
                      onClick={(e) => {
                        e.preventDefault();
                        profileUpdate();
                      }}
                    >
                      Save
                    </button>
                    <p className="pt-2 mb-0 text-center">
                      <Link to={"/changePassword"}>Change Password</Link>
                    </p>
                  </div>
                </fieldset>
              </form>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Profile;
