import {createStore,applyMiddleware} from 'redux'
import thunk from 'redux-thunk'
import {composeWithDevTools} from 'redux-devtools-extension'
import rootReducer from './../reducer/index'
import moment from 'moment'

export const initialState=[
    
    ["use1",{id:1,
    risk:{
        username:"",
        fieldname:"",
        town:"",
        province:"",
        latitude:"",
        longitude:"",
        date:moment(new Date()).format("MMMM DD, YY") ,
        rainfall:"",
        likelyhood:"",
        density:"",
        lasthost:"",
        estimation:"",
        severity:'',
        total:"",
        recomm:""
    }
    }],[

    "use2",{id:2,
    risk:{
        usename:"",
        fieldname:"",
        town:"",
        province:"",
        latitude:"",
        longitude:"",
        date:moment(new Date()).format("MMMM DD, YY") ,
        rainfall:"",
        likelyhood:"",
        density:"",
        lasthost:"",
        severity:'',
        estimation:"",
        total:"",
        recomm:""
    }
    }],
    [

    "use3",{id:3,
    risk:{
        username:"",
        fieldname:"",
        town:"",
        province:"",
        latitude:"",
        longitude:"",
        date:moment(new Date()).format("MMMM DD, YY") ,
        rainfall:"",
        likelyhood:"",
        density:"",
        lasthost:"",
        severity:'',
        estimation:"",
        total:"",
        recomm:""
    }
    }],
[
    "use4",{id:4,
    risk:{
        username:"",
        fieldname:"",
        town:"",
        province:"",
        latitude:"",
        longitude:"",
        date:moment(new Date()).format("MMMM DD, YY") ,
        rainfall:"",
        likelyhood:"",
        density:"",
        lasthost:"",
        severity:'',
        estimation:"",
        total:"",
        recomm:""
    }
    }],

    ["use5",{id:5,
    risk:{
        username:"",
        fieldname:"",
        town:"",
        province:"",
        latitude:"",
        longitude:"",
        date:moment(new Date()).format("MMMM DD, YY") ,
        rainfall:"",
        likelyhood:"",
        density:"",
        lasthost:"",
        severity:'',
        estimation:"",
        total:"",
        recomm:""
    }
    }
]]
const middleware=[thunk]
const store=createStore(
    rootReducer,
    initialState,
    composeWithDevTools(applyMiddleware(...middleware))
)
export default store