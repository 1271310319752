import React, {  } from "react";

const logos = [
    {
        name: "Canola Council of Canada",
        source: "logo_Canola_Council_Of_Canada",
    },
    {
        name: "Alberta Canola",
        source: "logo-alberta-canola-cropped",
    },
    {
        name: "SaskCanola",
        source: "logo-sask-canola",
    },
    {
        name: "Manitoba Canola Growers",
        source: "logo-manitoba-canola-growers",
    },
    {
        name: "Government of Canada wordmark",
        source: "canada-wordmark",
    }
];

const logoDisplay = logos.map((logo) => (
    <li><img src={`/sclerotinia-calc/img/${logo.source}.png`} alt={`${logo.name}`} className="funding-logo" /></li>
));

const Funding = () => {
    return (
        <div className="funding">
            <div className="container">
                <p className="funding-text">This tool was produced by the Canola Council of Canada and was funded in part by the Government of Canada under the previous Canadian Agricultural Partnership’s AgriScience Program, along with support from Alberta Canola, SaskCanola and Manitoba Canola Growers.</p>
                <ul className="funding-logos">
                    {logoDisplay}
                </ul>
            </div>
        </div>
    );
}
export default Funding;