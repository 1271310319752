import moment from "moment";
import React, { useCallback, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import swal from "sweetalert";
import {
  getDATApi,
  getPOSTapi,
} from "../../SclerotiniaRiskAssessment/utils/email";
import {
  ExportMultipleSystem,
  exportToCSV,
} from "../../SclerotiniaRiskAssessment/utils/ExportCSV";

import {
  Box,
  Card,
  Table,
  Stack,
  Avatar,
  Button,
  Checkbox,
  TableRow,
  TableBody,
  TableCell,
  Container,
  Typography,
  TableContainer,
  TablePagination
} from '@mui/material';

const RiskListing = () => {

  const [page, setPage] = useState(0);
  const [order, setOrder] = useState('asc');
  const [selected, setSelected] = useState([]);
  const [orderBy, setOrderBy] = useState('firstName');
  const [filterName, setFilterName] = useState('');
  const [rowsPerPage, setRowsPerPage] = useState(20);

  
  const [data, setdata] = useState([]);
  const [Checked, setChecked] = useState([]);
  const [RenameRow, setRenameRow] = useState([]);
  const [RenameValue, setRenameValue] = useState("");
  const { auth } = useSelector((state) => state.auth);

  const reportlisting = useCallback(async () => {
    if (RenameRow.length < 1) {
      const data = await getDATApi(`admin/adminRiskListing`);
      if (data.status) {
        setdata(data.result.sort((a, b) => new Date(b.date) - new Date(a.date)));
      }
    }
  }, []);

  useEffect(() => {
    reportlisting();
  }, []);

  const SaveData = (data) => {
    exportToCSV(data, data.location);
  };

  const handleChange = (e) => {
    let Ids = [...Checked];
    let value = e.target.value;
    if (Ids.includes(value)) {
      let Index = Ids.indexOf(value);
      Ids.splice(Index, 1);
    } else {
      Ids.push(value);
    }
    setChecked(Ids);
  };

  const AllCheck = (e) => {
    let Ids = [...Checked];

    if (!e.target.checked) {
      Ids = [];
    } else {
      data.map((value) => Ids.includes(value._id) || Ids.push(value._id));
    }

    setChecked(Ids);
  };

  const Download = () => {
    let Ids = [...Checked];
    let csvData = data.filter((value) => Ids.includes(value._id));
    ExportMultipleSystem(csvData, "Admin_risk assessment");
  };

  const RenameFile = (value) => {
    if (RenameRow.includes(value._id)) {
      submitRename();
    } else {
      setRenameRow([value._id]);
    }
    setRenameValue(value.fieldName);
  };

  const submitRename = async () => {
    if (RenameRow && RenameRow.length > 0) {
      const res = await getPOSTapi("admin/risk-update/" + RenameRow[0], {
        fieldName: RenameValue,
      });
      if (res.status) {
        setRenameRow([]);
        reportlisting();
      }
    }
  };
  
   const removeRecord = (id) => {
    if (id) {
      swal({
        title: "Are you sure?",
        text: "Once deleted, you will not be able to recover this!",
        icon: "warning",
        buttons: true,
        dangerMode: true,
      }).then(async (willDelete) => {
        if (willDelete) {
          const res = await getPOSTapi("admin/risk-remove/" + id, {});
          if (res.status) {
            setRenameRow([]);
            reportlisting();
          }
          swal("Poof! Your file has been deleted!", {
            icon: "success",
          });
        } else {
          swal("Your file is safe!");
        }
      });
    }
  };
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - data.length) : 0;

  return (
    <>
    <table class="table">
      <thead>
        <tr>
          <th width="8%" className="text-center" scope="col" onChange={AllCheck}>
            <input type="checkbox" id="all" name="" />
          </th>
          <th scope="col">File Name</th>
          <th scope="col">Date of risk assessment</th>
          <th scope="col">User name</th>
          <th scope="col">&nbsp;</th>
        </tr>
      </thead>
      <tbody>
        {data.length > 0 ? (
          data.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((value) => (
            <tr key={value._id}>
              <td className="text-right text-md-center" data-label="" onChange={handleChange}>
                <input
                  checked={Checked.includes(value._id)}
                  type="checkbox"
                  id=""
                  value={value._id}
                  name=""
                />
              </td>
              <td data-label="File Name">
                {RenameRow.includes(value._id) ? (
                  <>
                    <input
                      type="text"
                      onBlur={submitRename}
                      onKeyDown={(event) => {
                        if (event.key == "Enter") {
                          submitRename();
                        }
                      }}
                      value={RenameValue}
                      className="form-control form-control-sm"
                      onChange={(e) => {
                        setRenameValue(e.target.value);
                      }}
                    />
                  </>
                ) : (
                  `${value.fieldName}`
                )}
              </td>

              <td data-label="Date of risk assessment">{moment(value.date).format("MMM D, YYYY")}</td>
              <td data-label="" align="right" class="loadData">
                {value.firstName+" "+value.lastName}
                
              </td>
              <td data-label="" align="right" class="actionBtn">
                {/* <button
                  onClick={(e) => {
                    e.preventDefault();
                    removeRecord(value._id);
                  }}
                  className="btn btn-md"
                >
                  <i class="far fa-trash"></i>
                </button> */}
                <button
                  onClick={(e) => {
                    e.preventDefault();
                    SaveData(value);
                  }}
                  className="btn btn-md"
                >
                  <i class="fas fa-download"></i>
                </button>
              </td>
            </tr>
          ))
        ) : (
          <tr>
            <td colSpan={5}>
              <h5 className="text-center">No saved Reports</h5>
            </td>
          </tr>
        )}
      </tbody>
      <tfoot>
        <tr>
          <td colSpan={5}>
            {" "}
            <button
              className="btn btn-sm btn-primary"
              onClick={(e) => {
                e.preventDefault();
                Download();
              }}
              disabled={Checked.length < 1}
            >
              Download
            </button>
          </td>
        </tr>
      </tfoot>
    </table>

    <TablePagination
    rowsPerPageOptions={[10, 20, 50]}
    component={Box}
    count={data.length}
    rowsPerPage={rowsPerPage}
    page={page}
    onPageChange={handleChangePage}
    onRowsPerPageChange={handleChangeRowsPerPage}
    />

  </>

  );
};

export default React.memo(RiskListing);
