import React, { useEffect } from "react";
import { Col, Container, Nav, Row, Tab } from "react-bootstrap";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import Combinedreport from "./Combinedreport";

import Disease from "./Disease";
import RiskListing from "./Risk";

const Report = () => {
  let history = useHistory();
  useEffect(() => {
    if (
      !sessionStorage.getItem(
        "$2y$12$GPhjpmUHwgGcLQLlWf6qFOupo.nFXN59P6uLpanJ8HjHuec5kpVt"
      ) && sessionStorage.getItem("adminLogin")
    ) {
      history.push("/admin/login");
    }
  }, []);

  return (
    <section className="profile">
      <Container>
        <Row>
          <Col md={12}>
            <h1 class="prTitle">
              <span>Reports</span>
            </h1>
	  		
            <div className="upComing">
              <Tab.Container defaultActiveKey="third">
                <div class="d-flex justify-content-between align-items-center flex-wrap">
                  <Nav variant="tabs">
                    {/* <Nav.Item>
                      <Nav.Link eventKey="first">Risk assessment</Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link eventKey="second">
                        Sclerotinia severity rating
                      </Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link eventKey="third">
                        Combined
                      </Nav.Link>
                    </Nav.Item> */}
                  </Nav>
                  <a
                    href="mailto:betatest@sclerotiniariskcalculator.com"
                    class="cnBtn d-none"
                  >
                    Contact Us
                  </a>
                </div>
                <Tab.Content>
                  <Tab.Pane eventKey="first">
                    <RiskListing />
                  </Tab.Pane>
                  <Tab.Pane eventKey="second">
                    <Disease />
                  </Tab.Pane>
                  <Tab.Pane eventKey="third">
                    <Combinedreport />
                  </Tab.Pane>
                </Tab.Content>
              </Tab.Container>
            </div>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default React.memo(Report);
