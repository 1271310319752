import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";
import moment from "moment";
const fileType =
  "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
const fileExtension = ".xlsx";
export const exportToCSV = (csvData) => {
  let {
    firstName,
    lastName,
    email,
    phoneNo,
    companyName,
    location,
    createdDate,
    totalRiskforms,
    totalWorkbooks,
  } = csvData;

  let Heading = [
    [
      "First name",
      "Last name",
      "Email",
      "Phone No",
      "Company Name",
      "Location",
      "Account Created At",
      "Total Risk Forms",
      "Total Workbook",
    ],
  ];
  const fileData = [
    {
      "First name": firstName,
      "Last name": lastName,
      "Email": email,
      "Phone No": phoneNo,
      "Company Name": companyName,
      "Location": location,
      "Account Created At": createdDate ? moment(createdDate).format("M/D/YYYY") : "-",
      "Step 1": totalRiskforms,
      "Step 2": totalWorkbooks,
    },
  ];

  exportCsv(Heading, fileData, firstName);
};

export const ExportUsers = (csvData) => {
  let Heading = [
    [
      "First name",
      "Last name",
      "Email",
      "Phone No",
      "Company Name",
      "Location",
      "Account Created At",
      "Step 1",
      "Step 2",
    ],
  ];
  const fileData = [];

  csvData.map((value) => {
    fileData.push({
      "First name": value.firstName,
      "Last name": value.lastName,
      "Email": value.email,
      "Phone No": value.phoneNo,
      "Company Name": value.companyName,
      "Location": value.location,
      "Account Created At": value.createdDate ? moment(value.createdDate).format("M/D/YYYY") : "-",
      "Step 1": value.totalRiskforms,
      "Step 2": value.totalWorkbooks,
    });
  });

  exportCsv(Heading, fileData);
};

function exportCsv(Heading, fileData, name = "User List") {
  const ws = XLSX.utils.book_new();
  XLSX.utils.sheet_add_aoa(ws, Heading);

  //Starting in the second row to avoid overriding and skipping headers
  XLSX.utils.sheet_add_json(ws, fileData, { origin: "A2", skipHeader: true });

  const wb = { Sheets: { data: ws }, SheetNames: ["data"] };

  const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });

  const data = new Blob([excelBuffer], { type: fileType });

  FileSaver.saveAs(data, name + fileExtension);
}
//check now
