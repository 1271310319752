import React, { useState, useEffect } from "react";
import { useHistory } from "react-router";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { AuthLogin } from "../actions/workbook";
import { toast } from "react-toastify";
const LoginForm = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const history = useHistory();
  const dispatch = useDispatch();
  const { auth } = useSelector((state) => state.auth.auth);

  useEffect(() => {
    if (auth) {
      history.push("/");
    }
  }, []);

  async function login() {
    // email = email.toLowerCase();
    let item = { email, password };
    let result = await fetch(
      process.env.REACT_APP_BACKEND_URL + "/user/userLogin",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "applicaton/json",
        },
        body: JSON.stringify(item),
      }
    );
    result = await result.json();
    if (result.status == "true") {
      toast.success("Successfully logged in");
      dispatch(
        AuthLogin({
          ...(result?.response?.data || {}),
          ...(result.response.data ? { auth: true } : {}),
        })
      );
      let redirect = localStorage.getItem('redirect');
      localStorage.removeItem('redirect');
      history.push(redirect || "/");
    } else {
      toast.error(result.message);
    }
  }

  const backButton = () => {
    if (!localStorage.getItem('redirect') || localStorage.getItem('redirect') == 'reports') {
      return;
    }

    return (
      <a className="button" href={localStorage.getItem('redirect')}>Back</a>
    )
  }

  return (
    <section className="h-100 loginSection">
      <div className="container h-100 align-items-center">
        <div className="row justify-content-center align-items-center h-100">
          <div className="col-md-5 d-none d-lg-block">
            <div className="lockBg d-flex align-items-center justify-content-center">
              <img src="img/login-bg.jpg" className="img-fluid" />
            </div>
          </div>
          <div className="col-md-5 ">
            <div className="loginBox">
              <h1>
                <span>Login</span>
              </h1>
              <form
                className="form-horizontal"
                noValidate
                method="POST"
                action=""
              >
                <fieldset>
                  <div className="form-group position-relative">
                    <div className="iconImg">
                      <img src="img/email.png" />
                    </div>
                    <input
                      onChange={(e) => setEmail(e.target.value)}
                      id="email"
                      name="email"
                      type="email"
                      placeholder="Email"
                      className="form-control"
                    ></input>
                  </div>
                  <div className="form-group position-relative">
                    <div className="iconImg">
                      <img src="img/lock_icon.png" />
                    </div>
                    <input
                      onChange={(e) => setPassword(e.target.value)}
                      id="password"
                      name="password"
                      type="password"
                      placeholder="Password"
                      className="form-control"
                    ></input>
                  </div>
                  <div className="form-group">
                    <p>
                      <Link to={"/forgetPassword"}>Forgot password?</Link>
                    </p>
                    {backButton()}
                    <button
                      type="submit"
                      onClick={(e) => {
                        e.preventDefault();
                        login();
                      }}
                    >
                      Login
                    </button>
                    <p className="pt-4 text-center">
                      Don't have an account ?{" "}
                      <Link to="/signup">Sign up here</Link>
                    </p>
                  </div>
                </fieldset>
              </form>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default LoginForm;
