import React, { } from "react";
import { Link } from "react-router-dom";
import Funding from "./Funding";

const phone = '1-866-834-4378';
const email = 'admin@canolacouncil.org';

const Footer = () => {
    return (
        <div class="footer-wrapper">
            <Funding />
            <div className="footer">
                <div className="container">
                    <div className="row justify-content-between">
                        <div className="col-12 col-lg-3 footer-left-col">
                            <a href="https://www.canolacouncil.org">
                                <img src="img/logo-white.png" className="logo-image" alt="Canola Council of Canada" />
                            </a>
                            <div className="footer-address">
                                Canola Council of Canada<br />
                                400-167 Lombard Avenue<br />
                                Winnipeg, MB, R3B 0T6 <br />
                                Toll-Free: <a href={"tel:" + phone}>{phone}</a><br />
                                Email: <a href={"mailto:" + email}>{email}</a>
                            </div>
                        </div>
                        <div className="col-12 col-lg-9 footer-right-col">
                            <p>The Canola Calculator tools are made available to assist users with respect to agronomic practices, but should not be used as a replacement for professional advice. Consultation with an agronomist or lab analysis is strongly recommended prior to implementing any changes in seeding practices. The Canola Council of Canada disclaims any liability for any direct or indirect damages resulting from use of the Canola Calculator tools.</p>
                            <p>&copy; {new Date().getFullYear()} Canola Council of Canada | View our <Link to="/privacy-policy/">Privacy Policy</Link></p>
                            <p>
                                By clicking any of the links on this website, you are consenting to view materials that maybe commercial in nature.<br />
                                All content on this site is property of the Canola Council of Canada. All rights reserved.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Footer;