
import {UPDATE_WORKBOOK, DELETE_WORKBOOK } from './../Components/actions/actionTypes'
import moment from 'moment';
const initialState=[
    ["use1",{id:1,
    workbook:{
        date:null ,
       symptoms: "",
        lesions: "",
        wilt25: "",
        wilt50: "",
        wilt75: "",
        wilt100: "",
        plants: "",
        dsi: "",
        s_plants: "",
        s_dsi: "",
        s_wilt100: "",
        s_wilt75: "",
        s_wilt50: "",
        s_wilt25: "",
        s_lesions: "",
        s_symptoms: "",
        s_date: "",
    }
    }],
    ["use2",{id:2,
    workbook:{
        date:null ,
        symptoms:'',
        lesions:'',
        wilt25:'',
        wilt50:'',
        wilt75:'',
        wilt100:'',
        plants: '',
        dsi: '',
         s_plants: "",
        s_dsi: "",
        s_wilt100: "",
        s_wilt75: "",
        s_wilt50: "",
        s_wilt25: "",
        s_lesions: "",
        s_symptoms: "",
        s_date: "",
    }
    }],
    ["use3",{id:3,
    workbook:{
        date:null ,
        symptoms:'',
        lesions:'',
        wilt25:'',
        wilt50:'',
        wilt75:'',
        wilt100:'',
        plants: '',
        dsi: '',
         s_plants: "",
        s_dsi: "",
        s_wilt100: "",
        s_wilt75: "",
        s_wilt50: "",
        s_wilt25: "",
        s_lesions: "",
        s_symptoms: "",
        s_date: "",
    }
    }],
    ["use4",{id:4,
    workbook:{
        date:null ,
        symptoms:'',
        lesions:'',
        wilt25:'',
        wilt50:'',
        wilt75:'',
        wilt100:'',
        plants: '',
        dsi: '',
         s_plants: "",
        s_dsi: "",
        s_wilt100: "",
        s_wilt75: "",
        s_wilt50: "",
        s_wilt25: "",
        s_lesions: "",
        s_symptoms: "",
        s_date: "",
    }
    }],
    ["use5",{id:5,
    workbook:{
        date:null ,
        symptoms:'',
        lesions:'',
        wilt25:'',
        wilt50:'',
        wilt75:'',
        wilt100:'',
        plants: '',
        dsi: '',
         s_plants: "",
        s_dsi: "",
        s_wilt100: "",
        s_wilt75: "",
        s_wilt50: "",
        s_wilt25: "",
        s_lesions: "",
        s_symptoms: "",
        s_date: "",
    }
    }]
]

export default function (state = initialState, action) {
    const { payload } = action
    let id;
    if (payload){
        id=payload.id
    }
    switch (action.type) {
        case UPDATE_WORKBOOK:
            const field=payload.data.field
            return state.map(entry=>{
                if(entry[0]==id){
                    if(field=="date"){
                        entry[1].workbook.date=moment(payload.data.value).format("MM/DD/YY")
                    }
                    else if(field=="symptoms"){
                    entry[1].workbook.symptoms=payload.data.value
                    }
                    else if(field=="lesions"){
                        entry[1].workbook.lesions=payload.data.value
                    }
                    else if(field=="wilt25"){
                        entry[1].workbook.wilt25=payload.data.value
                    }
                    else if(field=="wilt50"){
                        entry[1].workbook.wilt50=payload.data.value
                    }
                    else if(field=="wilt75"){
                        entry[1].workbook.wilt75=payload.data.value
                    }
                    else if(field=="wilt100"){
                        entry[1].workbook.wilt100=payload.data.value
                    }
                    else if(field=="plants"){
                        entry[1].workbook.plants=payload.data.value
                    }
                    else if(field=="dsi"){
                        entry[1].workbook.dsi=payload.data.value
                    }else if(field=="s_date"){
                        entry[1].workbook.s_date=moment(payload.data.value).format("MM/DD/YY")
                    }
                    else if(field=="s_symptoms"){
                    entry[1].workbook.s_symptoms=payload.data.value
                    }
                    else if(field=="s_lesions"){
                        entry[1].workbook.s_lesions=payload.data.value
                    }
                    else if(field=="s_wilt25"){
                        entry[1].workbook.s_wilt25=payload.data.value
                    }
                    else if(field=="s_wilt50"){
                        entry[1].workbook.s_wilt50=payload.data.value
                    }
                    else if(field=="s_wilt75"){
                        entry[1].workbook.s_wilt75=payload.data.value
                    }
                    else if(field=="s_wilt100"){
                        entry[1].workbook.s_wilt100=payload.data.value
                    }
                    else if(field=="s_plants"){
                        entry[1].workbook.s_plants=payload.data.value
                    }
                    else if(field=="s_dsi"){
                        entry[1].workbook.s_dsi=payload.data.value
                    }
                }
                return entry
            })
        case DELETE_WORKBOOK:
            return state.map((entry,index)=>{
                if(entry[0]==id){
                    return [id,{id:1,
                        workbook:{
                            date:null ,
                            symptoms:'',
                            lesions:'',
                            wilt25:'',
                            wilt50:'',
                            wilt75:'',
                            wilt100:'',
                            plants: '',
                            dsi: '',
                             s_plants: "",
        s_dsi: "",
        s_wilt100: "",
        s_wilt75: "",
        s_wilt50: "",
        s_wilt25: "",
        s_lesions: "",
        s_symptoms: "",
        s_date: ""
                        }
                        }]
                }
                return entry
            })
        default:
            return state
    }
}

