import { AUTH, LOGOUT_AUTH, ADMIN_AUTH, ADMIN_LOGOUT_AUTH } from "../Components/actions/actionTypes";

const initialState = {
  auth: { auth: false },
  admin_auth: { admin_auth: false },
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case AUTH:
      return { ...state, auth: payload };
    case LOGOUT_AUTH:
      return { ...state, auth: { auth: false } };
    case ADMIN_AUTH:
      return { ...state, admin_auth: payload };
    case ADMIN_LOGOUT_AUTH:
      return { ...state, admin_auth: { auth: false } };
    default:
      return state;
  }
};
