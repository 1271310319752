import React from "react";
import TopBarProgress from "react-topbar-progress-indicator";
const MyLoader = () => {
  TopBarProgress.config({
    barColors: {
      0: "#00ff00",
      "1.0": "#fff",
    },
    shadowBlur: 5,
  });
  return (
    <div>
      <TopBarProgress />
    </div>
  );
};

export default MyLoader;
