import React, { useEffect } from "react";
import { Col, Container, Nav, Row, Tab } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

import Disease from "./Disease";
import RiskListing from "./Risk";
import Combinedreport from "./Combinedreport";
import { AuthLogOut } from "../actions/workbook";
import { toast } from "react-toastify";




const Report = () => {
  const History = useHistory();
  const { auth } = useSelector((state) => state.auth.auth);
  const dispatch = useDispatch();


  const logout = () => {
    dispatch(AuthLogOut({}));
    toast.success("Successfully logged out");
    History.push("/login");
  };

  useEffect(() => {
    if (!auth) {
      localStorage.setItem("redirect", 'reports');
      History.push("/login");
    }
  }, [auth]);

  return (
    <section className="profile">
      <Container>
        <Row>
          <Col md={12}>
            <h1 class="prTitle">
              <span>My fields</span>
            </h1>
            <p className="text-left">My fields is the area where you will find all the saved field data associated with Steps 1 and 2. Returning users can use the ‘My fields’ area to download completed assessments, find data on saved fields and complete disease severity ratings for Step 2.</p>
            <p><a href="mailto:manchurc@canolacouncil.org" target="_blank">Provide Feedback</a></p>
            <div className="upComing">
              <Tab.Container defaultActiveKey="third">
                <div class="d-flex justify-content-between align-items-center flex-wrap">
                  <Nav variant="tabs">
                    {/* <Nav.Item>
                      <Nav.Link eventKey="first">Step 1. Risk assessment</Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link eventKey="second">
                        Step 2. Sclerotinia severity rating
                      </Nav.Link>
                    </Nav.Item> */}
                    {/* <Nav.Item>
                      <Nav.Link eventKey="third">
                        Combined
                      </Nav.Link>
                    </Nav.Item> */}
                  </Nav>
                  <a
                    href="mailto:betatest@sclerotiniariskcalculator.com"
                    class="cnBtn d-none"
                  >
                    Contact Us
                  </a>
                </div>
                <Tab.Content>
                  {/* <Tab.Pane eventKey="first">
                    <RiskListing />
                  </Tab.Pane>
                  <Tab.Pane eventKey="second">
                    <Disease />
                  </Tab.Pane> */}
                  <Tab.Pane eventKey="third">
                    <Combinedreport />
                  </Tab.Pane>
                </Tab.Content>
              </Tab.Container>
            </div>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default React.memo(Report);
