import moment from "moment";
import React, { useCallback, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import {
  Box,
  Card,
  Table,
  Stack,
  Avatar,
  Button,
  Checkbox,
  TableRow,
  TableBody,
  TableCell,
  Container,
  Typography,
  TableContainer,
  TablePagination
} from '@mui/material';
import swal from "sweetalert";
import {
  getDATApi,
  getPOSTapi,
} from "../../SclerotiniaRiskAssessment/utils/email";
import {
  ExportUsers,
  exportToCSV,
} from "./ExportUSERCSV";

const UserListing = () => {

  const [page, setPage] = useState(0);
  const [order, setOrder] = useState('asc');
  const [selected, setSelected] = useState([]);
  const [orderBy, setOrderBy] = useState('firstName');
  const [filterName, setFilterName] = useState('');
  const [rowsPerPage, setRowsPerPage] = useState(20);



  const [data, setdata] = useState([]);
  const [Checked, setChecked] = useState([]);
  const [RenameRow, setRenameRow] = useState([]);
  const [RenameValue, setRenameValue] = useState("");
  const { auth } = useSelector((state) => state.auth);

  const reportlisting = useCallback(async () => {
    if (RenameRow.length < 1) {
      const data = await getDATApi(`admin/allUsers`);
      if (data.status) {
        setdata(data.result.sort((a, b) => new Date(b.date) - new Date(a.date)));
      }
    }
  }, []);
  

  useEffect(() => {
    reportlisting();
  }, []);

  const SaveData = (data) => {
    exportToCSV(data);
  };

  const handleChange = (e) => {
    let Ids = [...Checked];
    let value = e.target.value;
    if (Ids.includes(value)) {
      let Index = Ids.indexOf(value);
      Ids.splice(Index, 1);
    } else {
      Ids.push(value);
    }
    setChecked(Ids);
  };

  const AllCheck = (e) => {
    let Ids = [...Checked];

    if (!e.target.checked) {
      Ids = [];
    } else {
      data.map((value) => Ids.includes(value._id) || Ids.push(value._id));
    }

    setChecked(Ids);
  };

  const Download = () => {
    let csvData = data;
    ExportUsers(csvData);
  };

  
  
   const removeRecord = (id) => {
    if (id) {
      swal({
        title: "Are you sure?",
        text: "Once deleted, you will not be able to recover this!",
        icon: "warning",
        buttons: true,
        dangerMode: true,
      }).then(async (willDelete) => {
        if (willDelete) {
          const res = await getPOSTapi("admin/risk-remove/" + id, {});
          if (res.status) {
            setRenameRow([]);
            reportlisting();
          }
          swal("Poof! Your file has been deleted!", {
            icon: "success",
          });
        } else {
          swal("Your file is safe!");
        }
      });
    }
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - data.length) : 0;
  
  return (
    
      <>
    <Table class="table">
      <thead>
        <tr>
          {/* <th width="8%" className="text-center" scope="col" onChange={AllCheck}>
            <input type="checkbox" id="all" name="" />
          </th> */}
          <th scope="col">User Name</th>
          <th scope="col">Email</th>
          <th scope="col">Phone No</th>
          <th scope="col">Company Name</th>
          <th scope="col">Location</th>
          <th scope="col">Created At</th>
          <th scope="col">Step 1</th>
          <th scope="col">Step 2</th>
        </tr>
      </thead>
      <TableBody>
        {data.length > 0 ? (
          data.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((value) => (
            <TableRow key={value._id}>
              {/* <td className="text-right text-md-center" data-label="" onChange={handleChange}>
                <input
                  checked={Checked.includes(value._id)}
                  type="checkbox"
                  id=""
                  value={value._id}
                  name=""
                />
              </td> */}
              <TableCell>{value.firstName + " " + value.lastName}</TableCell>
              <TableCell align="left">{value.email}</TableCell>
              <TableCell align="left">{value.phoneNo}</TableCell>
              <TableCell align="left">{value.companyName}</TableCell>
              <TableCell align="left">{value.location}</TableCell>
              <TableCell align="left">{moment(value.createdDate).format("MMM D, YYYY")}</TableCell>
              <TableCell align="center">{value.totalRiskforms}</TableCell>
              <TableCell align="center">{value.totalWorkbooks}</TableCell>
            </TableRow>
          ))
        ) : (
          <TableRow>
            <TableCell colSpan={8}>
              <h5 className="text-center">No saved Reports</h5>
            </TableCell>
          </TableRow>
        )}

        {/* {emptyRows > 0 && (
          <TableRow style={{ height: 53 * emptyRows }}>
            <TableCell colSpan={8} />
          </TableRow>
        )} */}
      </TableBody>
      <tfoot>
        <tr>
          <td colSpan={8}>
            {" "}
            <Button variant="contained"
        onClick={(e) => {
          e.preventDefault();
          Download();
        }}
      >
        Download
      </Button>
          </td>
        </tr>
      </tfoot>
    </Table>
    <TablePagination
      rowsPerPageOptions={[10, 20, 50]}
      component={Box}
      count={data.length}
      rowsPerPage={rowsPerPage}
      page={page}
      onPageChange={handleChangePage}
      onRowsPerPageChange={handleChangeRowsPerPage}
    />
  </>

  );
};

export default React.memo(UserListing);
