import React, { useEffect, useState } from "react";

import { Link } from "react-router-dom";
import { useHistory } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import { AuthLogOut } from "./actions/workbook";
import { toast } from "react-toastify";

export const useWindowWidthAndHeight = () => {
  // 1- Get the size of window
  let windowInnerSize = [window.innerWidth, window.innerHeight];

  // 2- Define the state variable windowSize and pass windowInnerSize as its initial value
  let [windowSize, setWidowSize] = useState(windowInnerSize);

  useEffect(() => {
    const changeWindowSize = () => {
      setWidowSize([window.innerWidth, window.innerHeight]);
    };
    /* 3- add a 'resize' eventListener to window so that whenever 
the size of window changes the state variable windowSize changes and the component re-renders */
    window.addEventListener("resize", changeWindowSize);

    // 4- cleanup the 'resize' eventListener
    return () => window.removeEventListener("resize", changeWindowSize);
  }, []);
  // 5- return the window size
  return windowSize;
};

const Header = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const [width, height] = useWindowWidthAndHeight();
  const [headerClass, setHeaderClass] = useState("");
  const [expand, setExpand] = useState(false);
  const { auth } = useSelector((state) => state.auth.auth);
  const logout = () => {
    dispatch(AuthLogOut({}));
    toast.success("Successfully logged out");
    history.push("/login");
  };

  const links = [
    {
      name: "Seed Rate Calculator",
      link: "/calculator/seeding-rate",
      icon: "seed_rate",
    },
    {
      name: "Target Density Calculator",
      link: "/calculator/target-plant-density",
      icon: "target_density",
    },
    {
      name: "Canola Counts",
      link: "/calculator/canola-counts",
      icon: "canola",
    },
    {
      name: "Harvest Loss Calculator",
      link: "/calculator/harvest-loss",
      icon: "harvest_loss",
    },
    {
      name: "Blackleg Yield Loss Calculator",
      link: "/calculator/blackleg-loss",
      icon: "blackleg",
    },
    {
      name: "Combine Optimization Tool",
      link: "/calculator/combine-optimization",
      icon: "combine",
    },
    {
      name: "Sclerotinia Risk Assessment",
      link: "/sclerotinia-calc/",
      icon: "risk_assessment",
    },
    {
      name: "Sclerotinia Economic Calculator",
      link: "/sclerotinia-calc/economic-calculator",
      icon: "sclerotinia-calculator",
    },
    {
      name: "Sclerotinia Resources",
      link: "/sclerotinia-resources/",
      icon: "sclerotinia-resources",
    }
  ];

  // Dynamically add Login//Logout to links
  if (auth) {
    links.push({
      name: "Sclerotinia Logout",
      link: "/sclerotinia-calc/login",
      icon: "logout",
      action: logout,
    });
  } else {
    links.push({
      name: "Sclerotinia Login",
      link: "/sclerotinia-calc/login",
      icon: "sclerotinia-login",
    });
  }

  //Sclerotinia economic calculator, Sclerotinia Resources, Sclerotinia Risk Assessment

  const linkDisplay = links.map((link) => (
    <li>
      <a onClick={(link.name == 'Logout') ? () => { logout() } : ''} className={`icon-link icon-link--${link.icon}`} href={link.link}>
        {link.name}
      </a>
    </li>
  ));

  const adminLinks = [
    {
      'name': 'Admin Dashboard',
      'link': '/sclerotinia-calc/admin/dash'
    },
    {
      'name': 'Gallery',
      'link': '/sclerotinia-calc/admin/dashboard'
    },
    {
      'name': 'Users',
      'link': '/sclerotinia-calc/admin/users'
    },
    {
      'name': 'Reports',
      'link': '/sclerotinia-calc/admin/reports'
    },
  ]

  const adminDisplay = (sessionStorage.getItem("adminLogin") === true) ? adminLinks.map((link) => (
    <li>
      <a onClick={(link.name == 'Logout') ? () => { logout() } : ''} className={`icon-link icon-link--${link.icon}`} href={link.link}>
        {link.name}
      </a>
    </li>
  )) : '';

  window.addEventListener("scroll", () => {
    if (window.scrollY > 0) {
      setHeaderClass(`menuFix`);
      //document.body.classList.add("menuFix")
    } else {
      setHeaderClass("");
    }
  });

  return (
    <div className={`header ${headerClass} ${expand && "header-expand"}`}>
      <div className="container">
        <div className="row align-items-center justify-content-between">
          <div className="col-12 col-md-3 header-left-col">
            <a href="https://www.canolacouncil.org">
              <img src="img/logo.png" className="logo-image" alt="Canola Council of Canada" />
              <span className="logo-name">Canola Calculator</span>
            </a>
          </div>

          <div className="col-2 col-md-9 header-right-col">
            <div
              className={`stellarnav ${"right"} ${width < 1024 ? "mobile" : "desktop"
                } ${expand && "active"}`}
            >
              {width < 1024 && !expand && (
                <button onClick={() => setExpand(true)} class={"menu-toggle"}>
                  <span class="bars">
                    <span></span>
                    <span></span>
                    <span></span>
                  </span>{" "}
                  Menu
                </button>
              )}
              <ul>
                {expand && (
                  <button
                    onClick={() => setExpand(false)}
                    class={`${"closeMenu"} ${"full"}`}
                  >
                    <span class={"icon-close"}></span>
                  </button>
                )}
                <li class="mobile-logo">
                  <a href="https://www.canolacouncil.org">
                    <img src="img/logo.png" className="logo-image" alt="Canola Council of Canada" />
                  </a>
                </li>



                <li>
                  <span className="li-text">Canola Calculator Tools
                    <div className="arrow-down"></div>
                  </span>
                  <ul className="stellarnav-drop">
                    {adminDisplay}
                    {linkDisplay}
                  </ul>
                </li>
                <li className="conUs">
                  <a href="https://www.canolacouncil.org/calculator">
                    <img src="img/icon.png" />
                    <div>Return to <span>Canola Calculator</span></div>
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Header;
