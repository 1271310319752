import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";
import * as ExcelJS from "exceljs";
import moment from "moment";
const fileType =
  "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
const fileExtension = ".xlsx";
export const exportToCSV = (csvData, location) => {
  let {
    fieldName,
    firstName,
    lastName,
    date,
    rainfall,
    likelyhoodOfRain,
    plantDensity,
    hostCropYear,
    hostCropSeverarity,
    sclerotiniaGermination,
    rec,
  } = csvData;

  let Heading = [
    [
      "Field name",
      "First name",
      "Last name",
      "Latitude and longitude",
      "Date",
      "Rain fall in last 14 days",
      "Risk point",
      "Likelihood of rain in 3 day weather forecast",
      "Risk point",
      "Plant density",
      "Risk point",
      "Year since last host crop",
      "Risk point",
      "Sclerotinia severarity in last host corp",
      "Risk point",
      "Estimation of sclerotia germination",
      "Risk point",
      "Total risk points",
      "Fungicide spray recommendation",
    ],
  ];
  const fileData = [
    {
      "Field name": fieldName,
      "First name": firstName,
      "Last name": lastName,
      "Latitude and longitude": location,
      Date: moment(date).format("M/D/YYYY"),
      "Rain fall in last 14 days": rainfall.id,
      "Rainfall Risk point": rainfall.value,
      "Likelihood of rain in 3 day weather forecast": likelyhoodOfRain.id,
      "Weather forcast Risk point": likelyhoodOfRain.value,
      "Plant density":
        plantDensity.id === "Unknown1"
          ? "Unkown  = 5 Risk points"
          : plantDensity.id,
      "Plant density Risk point": plantDensity.value,
      "Year since last host crop":
        hostCropYear.id === "Unknown2"
          ? "Unkown  = 5 Risk points"
          : hostCropYear.id,
      "Crop Year Risk point": hostCropYear.value,
      "Sclerotinia severarity in last host corp":
        hostCropSeverarity.id === "Unknown3"
          ? "Unkown  = 5 Risk points"
          : hostCropSeverarity.id,
      "Sclerotinia severarity Risk point": hostCropSeverarity.value,
      "Estimation of sclerotia germination":
        sclerotiniaGermination.id === "Unknown4"
          ? "Unkown  = 5 Risk points"
          : sclerotiniaGermination.id,
      "Sclerotia germination Risk point": sclerotiniaGermination.value,
      "Total risk points":
        Number(rainfall.value) +
        Number(likelyhoodOfRain.value) +
        Number(plantDensity.value) +
        Number(hostCropYear.value) +
        Number(hostCropSeverarity.value) +
        Number(sclerotiniaGermination.value),
      "Fungicide spray recommendation": rec,
    },
  ];

  exportCsv(Heading, fileData, fieldName);
};

export const ExportMultipleSystem12 = (csvData, filename = "") => {
  let Heading = [
    [
      "Field name",
      "First name",
      "Last name",
      "Latitude and longitude",
      "Date",
      "Rain fall in last 14 days",
      "Risk point",
      "Likelihood of rain in 3 day weather forecast",
      "Risk point",
      "Plant density",
      "Risk point",
      "Year since last host crop",
      "Risk point",
      "Sclerotinia severarity in last host corp",
      "Risk point",
      "Estimation of sclerotia germination",
      "Risk point",
      "Total risk points",
      "Fungicide spray recommendation",
    ],
  ];
  const fileData = [];

  csvData.map((value) => {
    fileData.push({
      "Field name": value.fieldName,
      "First name": value.firstName,
      "Last name": value.lastName,
      "Latitude and longitude": value.location,
      Date: value.date ? moment(value.date).format("M/D/YYYY") : "",
      "Rain fall in last 14 days": value.rainfall.id,
      "Rainfall Risk point": value.rainfall.value,
      "Likelihood of rain in 3 day weather forecast": value.likelyhoodOfRain.id,
      "Weather forcast Risk point": value.likelyhoodOfRain.value,
      "Plant density":
        value.plantDensity.id === "Unknown1"
          ? "Unkown  = 5 Risk points"
          : value.plantDensity.id,
      "Plant density Risk point": value.plantDensity.value,
      "Year since last host crop":
        value.hostCropYear.id === "Unknown2"
          ? "Unkown  = 5 Risk points"
          : value.hostCropYear.id,
      "Crop Year Risk point": value.hostCropYear.value,
      "Sclerotinia severarity in last host corp":
        value.hostCropSeverarity.id === "Unknown3"
          ? "Unkown  = 5 Risk points"
          : value.hostCropSeverarity.id,
      "Sclerotinia severarity Risk point": value.hostCropSeverarity.value,
      "Estimation of sclerotia germination":
        value.sclerotiniaGermination.id === "Unknown4"
          ? "Unkown  = 5 Risk points"
          : value.sclerotiniaGermination.id,
      "Sclerotia germination Risk point": value.sclerotiniaGermination.value,
      "Total risk points":
        Number(value.rainfall.value) +
        Number(value.likelyhoodOfRain.value) +
        Number(value.plantDensity.value) +
        Number(value.hostCropYear.value) +
        Number(value.hostCropSeverarity.value) +
        Number(value.sclerotiniaGermination.value),
      "Fungicide spray recommendation": value.rec || "",
    });
  });
  if (!filename) {
    filename = "Risk assessment form";
  }
  exportCsv(Heading, fileData, filename);
};

function exportCsv(Heading, fileData, name = "Risk assessment form") {
  const ws = XLSX.utils.book_new();
  XLSX.utils.sheet_add_aoa(ws, Heading);

  //Starting in the second row to avoid overriding and skipping headers
  XLSX.utils.sheet_add_json(ws, fileData, { origin: "A2", skipHeader: true });

  const wb = { Sheets: { data: ws }, SheetNames: ["data"] };

  const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });

  const data = new Blob([excelBuffer], { type: fileType });

  FileSaver.saveAs(data, name + fileExtension);
}

export const ExportMultipleSystem = async (workbookData, filename = "") => {
  const wb = new ExcelJS.Workbook();
  if (!Array.isArray(workbookData)) {
    var workbooks = Array(workbookData);
  } else {
    var workbooks = workbookData;
  }
  var ws = wb.addWorksheet("Disease severity workbook");
  var cellHeaders = [
    "",
    "User name",
    "Field name",
    "Latitude and longitude",
    "Legal Land Description",
    "Municipality",
    "Date of risk assessment at flowering",
    "Rainfall in the last 14 days",
    "Likelihood of rain in 3-day weather forecast",
    "Plant density",
    "Years since last host crop",
    "Sclerotinia severity in last host crop",
    "Estimation of sclerotia germination",
    "Total risk points",
    "Fungicide recommendation",
  ];

  for (let index = 1; index <= cellHeaders.length; index++) {
    ws.getRow(index + 1).getCell(2).value = cellHeaders[index];
    ws.getRow(index + 1).height = 30;
  }

  ws.mergeCells("A2:A6");
  ws.mergeCells("A7:A15");
  ws.mergeCells("A16:A25");
  ws.mergeCells("A26:A35");

  ws.getCell("A2").value = "Field location";
  ws.getCell("A7").value = "Step 1. Sclerotinia risk assessment at flowering";

  ws.getCell("A2").alignment = {
    vertical: "middle",
    horizontal: "center",
    wrapText: true,
    textRotation: 90,
  };
  ws.getCell("A7").alignment = {
    vertical: "middle",
    horizontal: "center",
    wrapText: true,
    textRotation: 90,
  };
 

  ws.getColumn(2).alignment = {
    vertical: "middle",
    horizontal: "left",
    wrapText: true,
  };
  ws.getColumn(2).width = 40;
  var i = 0;
  var j = 3;
  workbooks.map((v) => {
    ws.getRow(2).getCell(j).value = v.firstName;
    ws.getRow(3).getCell(j).value = v.fieldName;
    if (v.latitude || v.latitude) {
      ws.getRow(4).getCell(j).value = v.latitude + " " + v.longitude;
    } else {
      ws.getRow(4).getCell(j).value = v.location;
    }
    ws.getRow(5).getCell(j).value = v.legalLand;
    ws.getRow(6).getCell(j).value = v.ruralMunicipality;

    ws.getRow(7).getCell(j).value = moment(v.date).format("L");
    ws.getRow(8).getCell(j).value = v.rainfall.value;
    ws.getRow(9).getCell(j).value = v.likelyhoodOfRain.value;
    ws.getRow(10).getCell(j).value = v.plantDensity.value;
    ws.getRow(11).getCell(j).value = v.hostCropYear.value;
    ws.getRow(12).getCell(j).value = v.hostCropSeverarity.value;
    ws.getRow(13).getCell(j).value = v.sclerotiniaGermination.value;
    ws.getRow(14).getCell(j).value = (parseInt(v.rainfall.value) + parseInt(v.likelyhoodOfRain.value) + parseInt(v.plantDensity.value) + parseInt(v.hostCropYear.value) + parseInt(v.hostCropSeverarity.value) + parseInt(v.sclerotiniaGermination.value));
    ws.getRow(15).getCell(j).value = v.rec;

    ws.getColumn(j).width = 20;
    ws.getColumn(j).alignment = {
      vertical: "middle",
      horizontal: "left",
      wrapText: true,
    };
    ws.getColumn(j + 1).width = 5;
    ws.getColumn(j + 1).alignment = {
      vertical: "middle",
      horizontal: "left",
      wrapText: true,
    };
    i = i+1;
    j = j+2;

  });
  ws.getColumn(1).eachCell(function (cell, colNumber) {
    if (cell.value)
      cell.fill = {
        type: "pattern",
        pattern: "solid",
        fgColor: {
          argb: "808080",
        },
      };
    cell.font = { color: { argb: "ffffff" }, bold: true };
    cell.border = {
      top: { style: "thin", color: { argb: "000000" } },
      left: { style: "thin", color: { argb: "000000" } },
      bottom: { style: "thin", color: { argb: "000000" } },
      right: { style: "thin", color: { argb: "000000" } },
    };
  });
  ws.getColumn(2).eachCell(function (cell, colNumber) {
    if (cell.value)
      cell.fill = {
        type: "pattern",
        pattern: "solid",
        fgColor: {
          argb: "808080",
        },
      };
    cell.font = { color: { argb: "ffffff" }, bold: true };
    cell.border = {
      top: { style: "thin", color: { argb: "000000" } },
      left: { style: "thin", color: { argb: "000000" } },
      bottom: { style: "thin", color: { argb: "000000" } },
      right: { style: "thin", color: { argb: "000000" } },
    };
  });

  if (!filename) {
    filename = workbooks[0].fieldName;
  }
  wb.xlsx.writeBuffer().then((buf) => {
    FileSaver.saveAs(new Blob([buf]), filename + `.xlsx`);
  });
};
//check now
