import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router";
import { Link } from "react-router-dom";
// import '../../styles/new_css/bootstrap.min.css'
const ChangePassword = () => {
  const [password, setPassword] = useState("");
  const [cpassword, setCpassword] = useState("");
  const { auth } = useSelector((state) => state.auth);
  const history = useHistory();

  useEffect(() => {
    if (!auth.auth) {
      history.push("/");
    }
  }, []);

  async function updatePassword() {
    // email = email.toLowerCase();
    const userId = auth.id;
    let item = {
      userId: userId,
      password: password,
      cpassword: cpassword,
    };
    let result = await fetch(
      process.env.REACT_APP_BACKEND_URL + "/user/changePassword",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "applicaton/json",
        },
        body: JSON.stringify(item),
      }
    );
    result = await result.json();
    if (result.status == "true") {
      alert(result.message);
      history.push("/");
    } else {
      alert(result.message);
    }
  }

  return (
    <section className="profile">
      <div className="container align-items-center">
        <div className="row justify-content-center no-gutters align-items-center">
          <div className="col-md-7 col-lg-5">
            <div className="loginBox m-auto">
              <h1>
                <span>Change password</span>
              </h1>
              <form className="form-horizontal" action="" method="post">
                <fieldset>
                  <div className="form-group position-relative">
                    <div className="iconImg">
                      <img src="img/lock_icon.png" />
                    </div>
                    <input
                      id="password"
                      name="password"
                      type="password"
                      placeholder="Enter new password"
                      className="form-control"
                      onChange={(e) => setPassword(e.target.value)}
                    />
                  </div>

                  <div className="form-group position-relative">
                    <div className="iconImg">
                      <img src="img/lock_icon.png" />
                    </div>
                    <input
                      id="cpassword"
                      name="cpassword"
                      type="password"
                      placeholder="Confirm new password"
                      className="form-control"
                      onChange={(e) => setCpassword(e.target.value)}
                    />
                  </div>

                  <div className="form-group mb-0 d-flex justify-content-between align-items-center subBtn">
                    <button
                      type="submit"
                      onClick={(e) => {
                        e.preventDefault();
                        updatePassword();
                      }}
                    >
                      Save
                    </button>
                  </div>
                </fieldset>
              </form>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ChangePassword;
