import React, { useEffect } from "react";
import { Col, Container, Nav, Row, Tab } from "react-bootstrap";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

import UserListing from "./UserList";

const User = () => {
  let history = useHistory();
  useEffect(() => {
    if (
      !sessionStorage.getItem(
        "$2y$12$GPhjpmUHwgGcLQLlWf6qFOupo.nFXN59P6uLpanJ8HjHuec5kpVt"
      )
    ) {
      history.push("/admin/login");
    }
  }, []);

  return (
    <section className="profile">
      <Container>
        <Row>
          <Col md={12}>
            <h1 class="prTitle">
              <span>Users</span>
            </h1>
	  		{/* <p className="text-left">‘My reports’ is the area where you will find all the saved data associated with your user profile. Below are two tabs – one for ‘Risk assessment’ and one for the ‘Sclerotinia severity rating’. Returning users can use the ‘My reports’ area to download completed assessments, find data on saved fields and complete disease severity ratings.</p> */}
            <div className="upComing">
              <Tab.Container defaultActiveKey="first">
                <div class="d-flex justify-content-between align-items-center flex-wrap">
                  <Nav variant="tabs">
                    {/* <Nav.Item>
                      <Nav.Link eventKey="first">Risk assessment</Nav.Link>
                    </Nav.Item> */}
                    
                  </Nav>
                  
                </div>
                <Tab.Content>
                  <Tab.Pane eventKey="first">
                    <UserListing />
                  </Tab.Pane>
                </Tab.Content>
              </Tab.Container>
            </div>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default React.memo(User);
