
import React, { useState, useEffect } from "react";
import { useHistory } from "react-router";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";

const SignupForm = () => {
  const [signupData, setSignupData] = useState({
    email: "",
    password: "",
    firstName: "",
    lastName: "",
    email: "",
  });
  const history = useHistory();

  async function signup() {
    // email = email.toLowerCase();
    let item = {
      email: signupData.email,
      password: signupData.password,
      firstName: signupData.firstName,
      lastName: signupData.lastName,
    };
    let result = await fetch(
      process.env.REACT_APP_BACKEND_URL + "/user/userRegister",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "applicaton/json",
        },
        body: JSON.stringify(item),
      }
    );
    result = await result.json();
    if (result.status == "true") {
      toast.success("Account Created Successfully");
      history.push("/login");
    } else {
      toast.error(result.message);
    }
  }

  return (
    <section className="loginSection h-100">
      <div className="container h-100 align-items-center">
        <div className="row justify-content-center align-items-center h-100">
          <div className="col-md-5 d-none d-lg-block">
            <div className="lockBg d-flex align-items-center justify-content-center">
              <img src="img/login-bg.jpg" className="img-fluid" />
            </div>
          </div>
          <div className="col-md-5">
            <div className="loginBox">
              <h1>
                <span>Sign up</span>
              </h1>
              <form className="form-horizontal" action="" method="post">
                <fieldset>
                  <div className="row">
                    <div className="col-md-6">
                      <div className="form-group position-relative ">
                        <div className="iconImg">
                          <img src="img/user.png" />
                        </div>
                        <input
                          id="firstName"
                          name="firstName"
                          type="text"
                          placeholder="First Name"
                          className="form-control"
                          onChange={(e) =>
                            setSignupData({
                              ...signupData,
                              firstName: e.target.value,
                            })
                          }
                        />
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-group position-relative">
                        <div className="iconImg">
                          <img src="img/user.png" />
                        </div>
                        <input
                          id="lastName"
                          name="lastName"
                          type="text"
                          placeholder="Last Name"
                          className="form-control"
                          onChange={(e) =>
                            setSignupData({
                              ...signupData,
                              lastName: e.target.value,
                            })
                          }
                        />
                      </div>
                    </div>
                  </div>

                  <div className="form-group position-relative">
                    <div className="iconImg">
                      <img src="img/email.png" />
                    </div>
                    <input
                      id="email"
                      name="email"
                      type="email"
                      placeholder="Email"
                      className="form-control"
                      onChange={(e) =>
                        setSignupData({ ...signupData, email: e.target.value })
                      }
                    />
                  </div>

                  <div className="form-group position-relative">
                    <div className="iconImg">
                      <img src="img/lock_icon.png" />
                    </div>
                    <input
                      id="password"
                      name="password"
                      type="password"
                      placeholder="Password"
                      className="form-control"
                      onChange={(e) =>
                        setSignupData({
                          ...signupData,
                          password: e.target.value,
                        })
                      }
                    />
                  </div>


                  <div className="form-group mb-0">
                    <button
                      type="submit"
                      onClick={(e) => {
                        e.preventDefault();
                        signup();
                      }}
                    >
                      Sign up
                    </button>
                    <p className="pt-2 mb-0 text-center">
                      Already have an account ?
                      <Link to={"/login"}> Login here</Link>
                    </p>
                  </div>
                </fieldset>
              </form>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default SignupForm;
