import { API_URL } from "../../url";

export default async function (formData, location) {
  let a = new Promise((resolve, reject) => {
    let b = fetch(API_URL + "send", {
      method: "POST",

      headers: {
        Accept: "application/json",
        "Content-type": "application/json",
      },
      body: JSON.stringify({ formData, location }),
    });
    b.then((result) => {
      result.json().then((res) => {
        resolve(res.msg);
      });
    });
    b.catch((error) => {
      reject(error);
    });
  });
  return a;
}

export const saveRiskAssessment = async function (formData, location) {
  let a = new Promise((resolve, reject) => {
    let b = fetch(process.env.REACT_APP_BACKEND_URL + "/admin/risk-assesment", {
      method: "POST",

      headers: {
        Accept: "application/json",
        "Content-type": "application/json",
      },
      body: JSON.stringify({ formData, location }),
    });
    b.then((result) => {
      result.json().then((res) => {
        resolve(res);
      });
    });
    b.catch((error) => {
      reject(error);
    });
  });
  return a;
};

export const getDATApi = async function (url) {
  let a = new Promise((resolve, reject) => {
    let b = fetch(`${process.env.REACT_APP_BACKEND_URL}/${url}`, {
      method: "GET",

      headers: {
        Accept: "application/json",
        "Content-type": "application/json",
      },
    });
    b.then((result) => {
      result.json().then((res) => {
        resolve(res);
      });
    });
    b.catch((error) => {
      reject(error);
    });
  });
  return a;
};

export const getPOSTapi = async function (url, data = {}) {
  let a = new Promise((resolve, reject) => {
    let b = fetch(`${process.env.REACT_APP_BACKEND_URL}/${url}`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-type": "application/json",
      },
      body: JSON.stringify(data),
    });
    b.then((result) => {
      result.json().then((res) => {
        resolve(res);
      });
    });
    b.catch((error) => {
      reject(error);
    });
  });
  return a;
};
