import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useHistory } from "react-router";
import { Modal, Button } from "react-bootstrap";

const Menu = () => {
  const [view, setView] = useState(false);
  const histry = useHistory();
  const Auth = localStorage.getItem('token');
  useEffect(() => {
    if (Auth) {
    } else {
      histry.push('/')

    }
  }, [Auth, histry]);
  return (
    <div>
      <Modal show={view} onHide={() => setView(false)}>
        <Modal.Body>{<img src="img/img2.png" width="100%" alt="calculation"></img>}</Modal.Body>
        <Modal.Footer>
          <Button onClick={() => setView(false)}>Close</Button>
        </Modal.Footer>
      </Modal>
      <section className="ourItems">
        <div className="container">
          <p className="text-center">*To save your data, you require an internet connection</p>
          <div className="row align-items-stretch">
            <div class="col-sm-12 col-md-6 col-lg-4 mt-4">
              <Link to="/sclerotiniariskassessment" class="frmBox h-100">
                <figure>
                  <img src="img/img1.png" className="img-fluid" alt="step-1"></img>
                </figure>
                <h3>
                  {/* Sclerotinia risk calculator with/without field identification */}
                  Step 1. Sclerotinia risk assessment at flowering
                </h3>
              </Link>
            </div>
            <div class="col-sm-12 col-md-6 col-lg-4 mt-4">
              {/* <Link class="frmBox h-100" to="/disease-severity-workbook"> */}
              <Link class="frmBox h-100" to="/reports">
                <figure>
                  <img src="img/img3.png" className="img-fluid" alt="step-2"></img>
                </figure>
                <h3>
                  {/* Workbook for recording of sclerotinia ratings in canola fields */}
                  Step 2. Sclerotinia severity rating at 30-60% seed colour change
                </h3>
              </Link>
            </div>
            
            
            
            

           

          </div>
        </div>
      </section>
    </div>
  );
};
export default Menu;
