import { SET_DISABLE, SET_ENABLE } from "../Components/actions/actionTypes";
const initialState=[
    ["use1",false],
    ["use2",false],
    ["use3",false],
    ["use4",false],
    ["use5",false]
]
export default function(state=initialState,action){
    const {type,payload}=action;
    const id=payload && payload.id
    switch(type){
        case SET_DISABLE:
            state.map(entry=>{
                if(entry[0]==id){
                    entry[1]=true
                }
                return entry
            })
            return state
        case SET_ENABLE:
            // const{id}=payload
            state.map(entry=>{
                if(entry[0]==id){
                    entry[1]=false
                }
                return entry
            })
            return state
        default:
            return state
    }
}