import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import AppRouter from './Components/AppRouter'
import reportWebVitals from './reportWebVitals';
import {Provider} from 'react-redux'
import store from './Components/store'
import './styles/main.css'
import './styles/new_css/stellarnav.css'
import { initGTM } from './gtag';
import { createBrowserHistory } from 'history';
var history = createBrowserHistory();




ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
    <AppRouter history={history} />
    </Provider>
  </React.StrictMode>,
  document.getElementById('root')
);

reportWebVitals();
