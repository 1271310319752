import React, { useState, useEffect, useRef } from "react";
import { useHistory } from "react-router";
import { Link } from "react-router-dom";
import { Box, Card, Stack, Avatar, Button, Container } from "@mui/material";
export const Dashboard = () => {
  let history = useHistory();
  //   const dispatch = useDispatch();
  //   const { admin_auth } = useSelector((state) => state.auth.admin_auth);
  useEffect(() => {
    if (
      !sessionStorage.getItem(
        "$2y$12$GPhjpmUHwgGcLQLlWf6qFOupo.nFXN59P6uLpanJ8HjHuec5kpVt"
      )
    ) {
      history.push("/admin/login");
    }
  }, []);
  let some = JSON.parse(
    sessionStorage.getItem(
      "$2y$12$GPhjpmUHwgGcLQLlWf6qFOupo.nFXN59P6uLpanJ8HjHuec5kpVt"
    )
  );

  return (
    <Box sx={{ minWidth: 275 }}>
      <section className="ourItems">
        <div className="container">
          <div className="row align-items-stretch">
            <div class="col-sm-12 col-md-6 col-lg-4 mt-4">
              <Link to="/admin/dashboard" class="frmBox h-100">
                <figure>
                  <img src="img/img6.png" className="img-fluid"></img>
                </figure>
                <h3>Photo gallery</h3>
              </Link>
            </div>

            <div class="col-sm-12 col-md-6 col-lg-4 mt-4">
              <Link to="/admin/users" class="frmBox h-100">
                <figure>
                  <img src="img/users_image.jpg" className="img-fluid"></img>
                </figure>
                <h3>Users</h3>
              </Link>
            </div>

            <div class="col-sm-12 col-md-6 col-lg-4 mt-4">
              <Link to="/admin/reports" class="frmBox h-100">
                <figure>
                  <img src="img/reports.png" className="img-fluid"></img>
                </figure>
                <h3>Reports</h3>
              </Link>
            </div>
          </div>
        </div>
      </section>
    </Box>
  );
};
export default Dashboard;
