import * as FileSaver from "file-saver";
import * as ExcelJS from "exceljs";
import moment from "moment";

const fileType =
  "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
const fileExtension = ".xlsx";

export const exportExcel_previous = async (workbookData, workbookname, filename = "") => {
  const wb = new ExcelJS.Workbook();
  var i = 1;
  if (!Array.isArray(workbookData.value)) {
    var workbooks = Array(workbookData.value);
  } else {
    var workbooks = workbookData.value;
  }
  var ws = wb.addWorksheet("Disease severity workbook");
  var dee = 0;
  workbooks.map((v) => {
    var cellHeaders = [
      "",
      "User name",
      "Field name",
      "Latitude and longitude",
      // "Longitude",
      "Date of risk assessment at flowering",
      "Rainfall in the last 14 days",
      "Likelihood of rain in 3-day weather forecast",
      "Plant density",
      "Years since last host crop",
      "Sclerotinia severity in last host crop",
      "Estimation of sclerotia germination",
      "Total risk points",
      "Fungicide recommendation",
      "Severity rating before harvest",
      "Date of disease severity assessment",
      "0. Number of plants with no symptoms",
      "1. Number of plants with superficial lesions",
      "2. Number of plants with 1-25% wilt",
      "3. Number of plants with 26-50% wilt",
      "4. Number of plants with 51-75% wilt",
      "5. Number of plants with 76-100% wilt",
      "Number of plants in the sample (optimal is 200, but can be any number)",
      "Disease severity index (DSI)",
    ];

    for (let index = 1; index <= cellHeaders.length; index++) {
      ws.getRow(index + dee).getCell(2).value = cellHeaders[index];
      ws.getRow(index + dee).height = 30;
    }



    ws.mergeCells("C" + (1 + dee) + ":D" + (1 + dee));
    ws.mergeCells("C" + (2 + dee) + ":D" + (2 + dee));
    ws.mergeCells("C" + (3 + dee) + ":D" + (3 + dee));
    ws.mergeCells("C" + (4 + dee) + ":D" + (4 + dee));
    ws.mergeCells("C" + (5 + dee) + ":D" + (5 + dee));
    ws.mergeCells("C" + (6 + dee) + ":D" + (6 + dee));
    ws.mergeCells("C" + (7 + dee) + ":D" + (7 + dee));
    ws.mergeCells("C" + (8 + dee) + ":D" + (8 + dee));
    ws.mergeCells("C" + (9 + dee) + ":D" + (9 + dee));
    ws.mergeCells("C" + (10 + dee) + ":D" + (10 + dee));
    ws.mergeCells("C" + (11 + dee) + ":D" + (11 + dee));
    ws.mergeCells("C" + (12 + dee) + ":D" + (12 + dee));
    ws.mergeCells("C" + (13 + dee) + ":D" + (13 + dee));

    ws.mergeCells("A" + (1 + dee) + ":A" + (3 + dee));
    ws.mergeCells("A" + (4 + dee) + ":A" + (12 + dee));
    ws.mergeCells("A" + (13 + dee) + ":A" + (22 + dee));
    ws.getCell("C" + (1 + dee)).value = workbookname;
    ws.getCell("A" + (2 + dee)).value = "Field location";
    ws.getCell("A" + (6 + dee)).value = "Sclerotinia risk assessment at flowering";
    ws.getCell("A" + (15 + dee)).value = "Disease severity assessment before harvest";
    ws.getCell("A" + (1 + dee)).alignment = {
      vertical: "middle",
      horizontal: "center",
      wrapText: true,
      textRotation: 90,
    };
    ws.getCell("A" + (4 + dee)).alignment = {
      vertical: "middle",
      horizontal: "center",
      wrapText: true,
      textRotation: 90,
    };
    ws.getCell("A" + (13 + dee)).alignment = {
      vertical: "middle",
      horizontal: "center",
      wrapText: true,
      textRotation: 90,
    };
    ws.getColumn(2).alignment = {
      vertical: "middle",
      horizontal: "left",
      wrapText: true,
    };
    ws.getColumn(2).width = 40;
    for (let i = 3; i < 4; i++) {
      ws.getRow(1 + dee).getCell(i).value = v.username;
      ws.getRow(2 + dee).getCell(i).value = v.fieldName;
      if (v.latitude || v.latitude) {
        ws.getRow(3 + dee).getCell(i).value = v.latitude + " " + v.longitude;
      } else {
        ws.getRow(3 + dee).getCell(i).value = "";
      }

      ws.getRow(5 + dee - 1).getCell(i).value = moment(v.date).format("L");
      ws.getRow(6 + dee - 1).getCell(i).value = v.rainfall;
      ws.getRow(7 + dee - 1).getCell(i).value = v.likelyhood;
      ws.getRow(8 + dee - 1).getCell(i).value = v.density;
      ws.getRow(9 + dee - 1).getCell(i).value = v.likelyhood;
      ws.getRow(10 + dee - 1).getCell(i).value = v.severity;
      ws.getRow(11 + dee - 1).getCell(i).value = v.estimation;
      ws.getRow(12 + dee - 1).getCell(i).value = v.total;
      ws.getRow(13 + dee - 1).getCell(i).value = v.recomm;
      ws.getRow(14 + dee - 1).getCell(i).value = "No Fungicide";
      ws.getRow(14 + dee - 1).getCell(i + 1).value = "Fungicide Applied";

      ws.getRow(15 + dee - 1).getCell(i).value = v.workbook_date;
      ws.getRow(15 + dee - 1).getCell(i + 1).value = v.s_date;

      ws.getRow(16 + dee - 1).getCell(i).value = v.symptoms;
      ws.getRow(16 + dee - 1).getCell(i + 1).value = v.s_symptoms;

      ws.getRow(17 + dee - 1).getCell(i).value = v.lesions;
      ws.getRow(17 + dee - 1).getCell(i + 1).value = v.s_lesions;

      ws.getRow(18 + dee - 1).getCell(i).value = v.wilt25;
      ws.getRow(18 + dee - 1).getCell(i + 1).value = v.s_wilt25;

      ws.getRow(19 + dee - 1).getCell(i).value = v.wilt50;
      ws.getRow(19 + dee - 1).getCell(i + 1).value = v.s_wilt50;

      ws.getRow(20 + dee - 1).getCell(i).value = v.wilt75;
      ws.getRow(20 + dee - 1).getCell(i + 1).value = v.s_wilt75;

      ws.getRow(21 + dee - 1).getCell(i).value = v.wilt100;
      ws.getRow(21 + dee - 1).getCell(i + 1).value = v.s_wilt100;

      ws.getRow(22 + dee - 1).getCell(i).value = v.plants;
      ws.getRow(22 + dee - 1).getCell(i + 1).value = v.s_plants;

      ws.getRow(23 + dee - 1).getCell(i).value = v.dsi;
      ws.getRow(23 + dee - 1).getCell(i + 1).value = v.s_dsi;

      ws.getColumn(i).width = 20;
      ws.getColumn(i).alignment = {
        vertical: "middle",
        horizontal: "left",
        wrapText: true,
      };
      ws.getColumn(i + 1).width = 20;
      ws.getColumn(i + 1).alignment = {
        vertical: "middle",
        horizontal: "left",
        wrapText: true,
      };
    }

    dee = dee + 22;
  });
  ws.getColumn(1).eachCell(function (cell, colNumber) {
    if (cell.value)
      cell.fill = {
        type: "pattern",
        pattern: "solid",
        fgColor: {
          argb: "808080",
        },
      };
    cell.font = { color: { argb: "ffffff" }, bold: true };
    cell.border = {
      top: { style: "thin", color: { argb: "000000" } },
      left: { style: "thin", color: { argb: "000000" } },
      bottom: { style: "thin", color: { argb: "000000" } },
      right: { style: "thin", color: { argb: "000000" } },
    };
  });
  ws.getColumn(2).eachCell(function (cell, colNumber) {
    if (cell.value)
      cell.fill = {
        type: "pattern",
        pattern: "solid",
        fgColor: {
          argb: "808080",
        },
      };
    cell.font = { color: { argb: "ffffff" }, bold: true };
    cell.border = {
      top: { style: "thin", color: { argb: "000000" } },
      left: { style: "thin", color: { argb: "000000" } },
      bottom: { style: "thin", color: { argb: "000000" } },
      right: { style: "thin", color: { argb: "000000" } },
    };
  });

  if (!filename) {
    filename = workbooks[0].fieldName;
  }
  wb.xlsx.writeBuffer().then((buf) => {
    FileSaver.saveAs(new Blob([buf]), filename + `.xlsx`);
  });
};

export const exportExcel_original = async (workbookData, workbookname) => {
  const {
    date,
    density,
    dsi,
    email,
    estimation,
    fieldName,
    firstName,
    id,
    lastName,
    latitude,
    lesions,
    likelyhood,
    location,
    longitude,
    plants,
    rainfall,
    recomm,
    severity,
    symptoms,
    token,
    total,
    username,
    wilt25,
    wilt50,
    wilt75,
    wilt100,
    s_date,
    s_dsi,
    s_lesions,
    s_plants,
    s_symptoms,
    s_wilt25,
    s_wilt50,
    s_wilt75,
    s_wilt100,
    workbook_date,
  } = workbookData;
  const wb = new ExcelJS.Workbook();
  const ws = wb.addWorksheet("Disease severity workbook");

  const cellHeaders = [
    "",
    "",
    "User name",
    "Field name",
    "Latitude and longitude",
    // "Longitude",
    "Date of risk assessment at flowering",
    "Rainfall in the last 14 days",
    "Likelihood of rain in 3-day weather forecast",
    "Plant density",
    "Years since last host crop",
    "Sclerotinia severity in last host crop",
    "Estimation of sclerotia germination",
    "Total risk points",
    "Fungicide recommendation",
    "Severity rating before harvest",
    "Date of disease severity assessment",
    "0. Number of plants with no symptoms",
    "1. Number of plants with superficial lesions",
    "2. Number of plants with 1-25% wilt",
    "3. Number of plants with 26-50% wilt",
    "4. Number of plants with 51-75% wilt",
    "5. Number of plants with 76-100% wilt",
    "Number of plants in the sample (optimal is 200, but can be any number)",
    "Disease severity index (DSI)",
  ];
  for (let index = 1; index <= cellHeaders.length; index++) {
    ws.getRow(index).getCell(2).value = cellHeaders[index];
    ws.getRow(index).height = 30;
  }

  ws.mergeCells("C1:D1");
  ws.mergeCells("C2:D2");
  ws.mergeCells("C3:D3");
  ws.mergeCells("C4:D4");
  ws.mergeCells("C5:D5");
  ws.mergeCells("C6:D6");
  ws.mergeCells("C7:D7");
  ws.mergeCells("C8:D8");
  ws.mergeCells("C9:D9");
  ws.mergeCells("C10:D10");
  ws.mergeCells("C11:D11");
  ws.mergeCells("C12:D12");
  ws.mergeCells("C13:D13");

  ws.mergeCells("A2:A4");
  ws.mergeCells("A5:A13");
  ws.mergeCells("A14:A23");
  ws.getCell("C1").value = workbookname;
  ws.getCell("A2").value = "Field location";
  ws.getCell("A6").value = "Sclerotinia risk assessment at flowering";
  ws.getCell("A15").value = "Disease severity assessment before harvest";
  ws.getCell("A2").alignment = {
    vertical: "middle",
    horizontal: "center",
    wrapText: true,
    textRotation: 90,
  };
  ws.getCell("A6").alignment = {
    vertical: "middle",
    horizontal: "center",
    wrapText: true,
    textRotation: 90,
  };
  ws.getCell("A15").alignment = {
    vertical: "middle",
    horizontal: "center",
    wrapText: true,
    textRotation: 90,
  };
  ws.getColumn(2).alignment = {
    vertical: "middle",
    horizontal: "center",
    wrapText: true,
  };
  ws.getColumn(2).width = 40;

  for (let i = 3; i < 4; i++) {
    ws.getRow(2).getCell(i).value = username;
    ws.getRow(3).getCell(i).value = fieldName;
    if (latitude || latitude) {
      ws.getRow(4).getCell(i).value = latitude + " " + longitude;
    } else {
      ws.getRow(4).getCell(i).value = "";
    }

    ws.getRow(5).getCell(i).value = moment(date).format("L");
    ws.getRow(6).getCell(i).value = rainfall;
    ws.getRow(7).getCell(i).value = likelyhood;
    ws.getRow(8).getCell(i).value = density;
    ws.getRow(9).getCell(i).value = likelyhood;
    ws.getRow(10).getCell(i).value = severity;
    ws.getRow(11).getCell(i).value = estimation;
    ws.getRow(12).getCell(i).value = total;
    ws.getRow(13).getCell(i).value = recomm;
    ws.getRow(14).getCell(i).value = "No Fungicide";
    ws.getRow(14).getCell(i + 1).value = "Fungicide Applied";

    ws.getRow(15).getCell(i).value = workbook_date;
    ws.getRow(15).getCell(i + 1).value = s_date;

    ws.getRow(16).getCell(i).value = symptoms;
    ws.getRow(16).getCell(i + 1).value = s_symptoms;

    ws.getRow(17).getCell(i).value = lesions;
    ws.getRow(17).getCell(i + 1).value = s_lesions;

    ws.getRow(18).getCell(i).value = wilt25;
    ws.getRow(18).getCell(i + 1).value = s_wilt25;

    ws.getRow(19).getCell(i).value = wilt50;
    ws.getRow(19).getCell(i + 1).value = s_wilt50;

    ws.getRow(20).getCell(i).value = wilt75;
    ws.getRow(20).getCell(i + 1).value = s_wilt75;

    ws.getRow(21).getCell(i).value = wilt100;
    ws.getRow(21).getCell(i + 1).value = s_wilt100;

    ws.getRow(22).getCell(i).value = plants;
    ws.getRow(22).getCell(i + 1).value = s_plants;

    ws.getRow(23).getCell(i).value = dsi;
    ws.getRow(23).getCell(i + 1).value = s_dsi;

    ws.getColumn(i).width = 20;
    ws.getColumn(i).alignment = {
      vertical: "middle",
      horizontal: "left",
      wrapText: true,
    };
    ws.getColumn(i + 1).width = 20;
    ws.getColumn(i + 1).alignment = {
      vertical: "middle",
      horizontal: "left",
      wrapText: true,
    };
  }
  ws.getColumn(1).eachCell(function (cell, colNumber) {
    if (cell.value)
      cell.fill = {
        type: "pattern",
        pattern: "solid",
        fgColor: {
          argb: "808080",
        },
      };
    cell.font = { color: { argb: "ffffff" }, bold: true };
    cell.border = {
      top: { style: "thin", color: { argb: "000000" } },
      left: { style: "thin", color: { argb: "000000" } },
      bottom: { style: "thin", color: { argb: "000000" } },
      right: { style: "thin", color: { argb: "000000" } },
    };
  });
  ws.getColumn(2).eachCell(function (cell, colNumber) {
    if (cell.value)
      cell.fill = {
        type: "pattern",
        pattern: "solid",
        fgColor: {
          argb: "808080",
        },
      };
    cell.font = { color: { argb: "ffffff" }, bold: true };
    cell.border = {
      top: { style: "thin", color: { argb: "000000" } },
      left: { style: "thin", color: { argb: "000000" } },
      bottom: { style: "thin", color: { argb: "000000" } },
      right: { style: "thin", color: { argb: "000000" } },
    };
  });
  wb.xlsx.writeBuffer().then((buf) => {
    FileSaver.saveAs(new Blob([buf]), fieldName + `.xlsx`);
  });
};

export const exportMultipleExcel_original = async (workbookData, workbookname) => {
  const wb = new ExcelJS.Workbook();
  const ws = wb.addWorksheet("Disease severity workbook");
  const dataLength = workbookData.length;
  let a = 1;
  let b = 13;
  let c = 0;
  let xx = 1;
  workbookData.map((dataValue, j) => {
    const cellHeaders = [
      "",
      "",
      "User name",
      "Field name",
      "Latitude and longitude",
      // "Longitude",
      "Date of risk assessment at flowering",
      "Rainfall in the last 14 days",
      "Likelihood of rain in 3-day weather forecast",
      "Plant density",
      "Years since last host crop",
      "Sclerotinia severity in last host crop",
      "Estimation of sclerotia germination",
      "Total risk points",
      "Fungicide recommendation",
      "Severity rating before harvest",
      "Date of disease severity assessment",
      "0. Number of plants with no symptoms",
      "1. Number of plants with superficial lesions",
      "2. Number of plants with 1-25% wilt",
      "3. Number of plants with 26-50% wilt",
      "4. Number of plants with 51-75% wilt",
      "5. Number of plants with 76-100% wilt",
      "Number of plants in the sample (optimal is 200, but can be any number)",
      "Disease severity index (DSI)",
    ];

    for (let index = xx; index <= cellHeaders.length * dataLength; index++) {
      let dataindex = index;
      if (index <= 23) {
        dataindex = index;
      } else {
        dataindex = index - 23 * j;
      }
      ws.getRow(index).getCell(2).value = cellHeaders[dataindex];
      ws.getRow(index).height = 30;
    }
    xx = xx + 23;

    for (let x = a; x <= b; x++) {
      let sells = "C" + x + ":D" + x;
      ws.mergeCells(sells);
    }

    let sells1 = "A" + (a + 1) + ":A" + (a + 3);
    let sells2 = "A" + (a + 4) + ":A" + (a + 12);
    let sells3 = "A" + (a + 13) + ":A" + (a + 22);
    ws.mergeCells(sells1);
    ws.mergeCells(sells2);
    ws.mergeCells(sells3);

    ws.getCell("A" + (a + 1)).value = "Field location";
    ws.getCell("A" + (a + 5)).value =
      "Sclerotinia risk assessment at flowering";
    ws.getCell("A" + (a + 14)).value =
      "Disease severity assessment before harvest";

    ws.getCell("A" + (a + 1)).alignment = {
      vertical: "middle",
      horizontal: "center",
      wrapText: true,
      textRotation: 90,
    };
    ws.getCell("A" + (a + 5)).alignment = {
      vertical: "middle",
      horizontal: "center",
      wrapText: true,
      textRotation: 90,
    };
    ws.getCell("A" + (a + 14)).alignment = {
      vertical: "middle",
      horizontal: "center",
      wrapText: true,
      textRotation: 90,
    };

    a = a + 23;
    b = b + 23;

    ws.getColumn(2).alignment = {
      vertical: "middle",
      horizontal: "center",
      wrapText: true,
    };
    ws.getColumn(2).width = 40;

    for (let i = 3; i < 4; i++) {
      ws.getRow(c + 2).getCell(i).value = dataValue.username;
      ws.getRow(c + 3).getCell(i).value = dataValue.fieldName;
      if (dataValue.latitude || dataValue.latitude) {
        ws.getRow(c + 4).getCell(i).value =
          dataValue.latitude + " " + dataValue.longitude;
      } else {
        ws.getRow(c + 4).getCell(i).value = "";
      }

      ws.getRow(c + 5).getCell(i).value = moment(dataValue.date).format("L");
      ws.getRow(c + 6).getCell(i).value = dataValue.rainfall;
      ws.getRow(c + 7).getCell(i).value = dataValue.likelyhood;
      ws.getRow(c + 8).getCell(i).value = dataValue.density;
      ws.getRow(c + 9).getCell(i).value = dataValue.likelyhood;
      ws.getRow(c + 10).getCell(i).value = dataValue.severity;
      ws.getRow(c + 11).getCell(i).value = dataValue.estimation;
      ws.getRow(c + 12).getCell(i).value = dataValue.total;
      ws.getRow(c + 13).getCell(i).value = dataValue.recomm;
      ws.getRow(c + 14).getCell(i).value = "No Fungicide";
      ws.getRow(c + 14).getCell(i + 1).value = "Fungicide Applied";

      ws.getRow(c + 15).getCell(i).value = dataValue.workbook_date;
      ws.getRow(c + 15).getCell(i + 1).value = dataValue.s_date;

      ws.getRow(c + 16).getCell(i).value = dataValue.symptoms;
      ws.getRow(c + 16).getCell(i + 1).value = dataValue.s_symptoms;

      ws.getRow(c + 17).getCell(i).value = dataValue.lesions;
      ws.getRow(c + 17).getCell(i + 1).value = dataValue.s_lesions;

      ws.getRow(c + 18).getCell(i).value = dataValue.wilt25;
      ws.getRow(c + 18).getCell(i + 1).value = dataValue.s_wilt25;

      ws.getRow(c + 19).getCell(i).value = dataValue.wilt50;
      ws.getRow(c + 19).getCell(i + 1).value = dataValue.s_wilt50;

      ws.getRow(c + 20).getCell(i).value = dataValue.wilt75;
      ws.getRow(c + 20).getCell(i + 1).value = dataValue.s_wilt75;

      ws.getRow(c + 21).getCell(i).value = dataValue.wilt100;
      ws.getRow(c + 21).getCell(i + 1).value = dataValue.s_wilt100;

      ws.getRow(c + 22).getCell(i).value = dataValue.plants;
      ws.getRow(c + 22).getCell(i + 1).value = dataValue.s_plants;

      ws.getRow(c + 23).getCell(i).value = dataValue.dsi;
      ws.getRow(c + 23).getCell(i + 1).value = dataValue.s_dsi;

      ws.getColumn(i).width = 20;
      ws.getColumn(i).alignment = {
        vertical: "middle",
        horizontal: "left",
        wrapText: true,
      };
      ws.getColumn(i + 1).width = 20;
      ws.getColumn(i + 1).alignment = {
        vertical: "middle",
        horizontal: "left",
        wrapText: true,
      };
    }

    c = c + 23;
  });
  ws.getColumn(1).eachCell(function (cell, colNumber) {
    if (cell.value)
      cell.fill = {
        type: "pattern",
        pattern: "solid",
        fgColor: {
          argb: "808080",
        },
      };
    cell.font = { color: { argb: "ffffff" }, bold: true };
    cell.border = {
      top: { style: "thin", color: { argb: "000000" } },
      left: { style: "thin", color: { argb: "000000" } },
      bottom: { style: "thin", color: { argb: "000000" } },
      right: { style: "thin", color: { argb: "000000" } },
    };
  });
  ws.getColumn(2).eachCell(function (cell, colNumber) {
    if (cell.value)
      cell.fill = {
        type: "pattern",
        pattern: "solid",
        fgColor: {
          argb: "808080",
        },
      };
    cell.font = { color: { argb: "ffffff" }, bold: true };
    cell.border = {
      top: { style: "thin", color: { argb: "000000" } },
      left: { style: "thin", color: { argb: "000000" } },
      bottom: { style: "thin", color: { argb: "000000" } },
      right: { style: "thin", color: { argb: "000000" } },
    };
  });
  wb.xlsx.writeBuffer().then((buf) => {
    FileSaver.saveAs(new Blob([buf]), "fieldName" + `.xlsx`);
  });
};

export const exportMultipleExcel = async (workbookData, workbookname) => {
  const wb = new ExcelJS.Workbook();
  const ws = wb.addWorksheet("Disease severity workbook");

  const cellHeaders = [
    "",
    "",
    "User name",
    "Field name",
    "Latitude and longitude",
    // "Longitude",
    "Date of risk assessment at flowering",
    "Rainfall in the last 14 days",
    "Likelihood of rain in 3-day weather forecast",
    "Plant density",
    "Years since last host crop",
    "Sclerotinia severity in last host crop",
    "Estimation of sclerotia germination",
    "Total risk points",
    "Fungicide recommendation",
    "Severity rating before harvest",
    "Date of disease severity assessment",
    "0. Number of plants with no symptoms",
    "1. Number of plants with superficial lesions",
    "2. Number of plants with 1-25% wilt",
    "3. Number of plants with 26-50% wilt",
    "4. Number of plants with 51-75% wilt",
    "5. Number of plants with 76-100% wilt",
    "Number of plants in the sample (optimal is 200, but can be any number)",
    "Disease severity index (DSI)",

    "Severity rating After harvest",
    "Date of disease severity assessment",
    "0. Number of plants with no symptoms",
    "1. Number of plants with superficial lesions",
    "2. Number of plants with 1-25% wilt",
    "3. Number of plants with 26-50% wilt",
    "4. Number of plants with 51-75% wilt",
    "5. Number of plants with 76-100% wilt",
    "Number of plants in the sample (optimal is 200, but can be any number)",
    "Disease severity index (DSI)",
  ];
  for (let index = 1; index <= cellHeaders.length; index++) {
    ws.getRow(index).getCell(2).value = cellHeaders[index];
    ws.getRow(index).height = 30;
  }

  ws.mergeCells("C1:D1");

  ws.mergeCells("A2:A4");
  ws.mergeCells("A5:A13");
  ws.mergeCells("A14:A23");
  ws.mergeCells("A24:A33");
  ws.getCell("C1").value = workbookname;
  ws.getCell("A2").value = "Field location";
  ws.getCell("A6").value = "Sclerotinia risk assessment at flowering";
  ws.getCell("A15").value = "Disease severity assessment before harvest";
  ws.getCell("A25").value = "Disease severity assessment After harvest";
  ws.getCell("A2").alignment = {
    vertical: "middle",
    horizontal: "center",
    wrapText: true,
    textRotation: 90,
  };
  ws.getCell("A6").alignment = {
    vertical: "middle",
    horizontal: "center",
    wrapText: true,
    textRotation: 90,
  };
  ws.getCell("A15").alignment = {
    vertical: "middle",
    horizontal: "center",
    wrapText: true,
    textRotation: 90,
  };
  ws.getCell("A25").alignment = {
    vertical: "middle",
    horizontal: "center",
    wrapText: true,
    textRotation: 90,
  };
  ws.getColumn(2).alignment = {
    vertical: "middle",
    horizontal: "center",
    wrapText: true,
  };
  ws.getColumn(2).width = 40;
  workbookData.map((dataValue, j) => {
    ws.getRow(2).getCell(j + 3).value = dataValue.username;
    ws.getRow(3).getCell(j + 3).value = dataValue.fieldName;
    if (dataValue.latitude || dataValue.latitude) {
      ws.getRow(4).getCell(j + 3).value =
        dataValue.latitude + " " + dataValue.longitude;
    } else {
      ws.getRow(4).getCell(j + 3).value = "";
    }

    ws.getRow(5).getCell(j + 3).value = moment(dataValue.date).format("L");
    ws.getRow(6).getCell(j + 3).value = dataValue.rainfall;
    ws.getRow(7).getCell(j + 3).value = dataValue.likelyhood;
    ws.getRow(8).getCell(j + 3).value = dataValue.density;
    ws.getRow(9).getCell(j + 3).value = dataValue.likelyhood;
    ws.getRow(10).getCell(j + 3).value = dataValue.severity;
    ws.getRow(11).getCell(j + 3).value = dataValue.estimation;
    ws.getRow(12).getCell(j + 3).value = dataValue.total;
    ws.getRow(13).getCell(j + 3).value = dataValue.recomm;
    ws.getRow(14).getCell(j + 3).value = "No Fungicide";

    ws.getRow(15).getCell(j + 3).value = dataValue.workbook_date;

    ws.getRow(16).getCell(j + 3).value = dataValue.symptoms;

    ws.getRow(17).getCell(j + 3).value = dataValue.lesions;

    ws.getRow(18).getCell(j + 3).value = dataValue.wilt25;

    ws.getRow(19).getCell(j + 3).value = dataValue.wilt50;

    ws.getRow(20).getCell(j + 3).value = dataValue.wilt75;

    ws.getRow(21).getCell(j + 3).value = dataValue.wilt100;

    ws.getRow(22).getCell(j + 3).value = dataValue.plants;

    ws.getRow(23).getCell(j + 3).value = dataValue.dsi;
    ws.getRow(24).getCell(j + 3).value = "Fungicide Applied";
    ws.getRow(25).getCell(j + 3).value = dataValue.s_date;
    ws.getRow(26).getCell(j + 3).value = dataValue.s_symptoms;
    ws.getRow(27).getCell(j + 3).value = dataValue.s_lesions;
    ws.getRow(28).getCell(j + 3).value = dataValue.s_wilt25;
    ws.getRow(29).getCell(j + 3).value = dataValue.s_wilt50;
    ws.getRow(30).getCell(j + 3).value = dataValue.s_wilt75;
    ws.getRow(31).getCell(j + 3).value = dataValue.s_wilt100;
    ws.getRow(32).getCell(j + 3).value = dataValue.s_plants;
    ws.getRow(33).getCell(j + 3).value = dataValue.s_dsi;

    ws.getColumn(j + 3).width = 20;
    ws.getColumn(j + 3).alignment = {
      vertical: "middle",
      horizontal: "left",
      wrapText: true,
    };
    ws.getColumn(j + 3 + 1).width = 20;
    ws.getColumn(j + 3 + 1).alignment = {
      vertical: "middle",
      horizontal: "left",
      wrapText: true,
    };
  });
  ws.getColumn(1).eachCell(function (cell, colNumber) {
    if (cell.value)
      cell.fill = {
        type: "pattern",
        pattern: "solid",
        fgColor: {
          argb: "808080",
        },
      };
    cell.font = { color: { argb: "ffffff" }, bold: true };
    cell.border = {
      top: { style: "thin", color: { argb: "000000" } },
      left: { style: "thin", color: { argb: "000000" } },
      bottom: { style: "thin", color: { argb: "000000" } },
      right: { style: "thin", color: { argb: "000000" } },
    };
  });
  ws.getColumn(2).eachCell(function (cell, colNumber) {
    if (cell.value)
      cell.fill = {
        type: "pattern",
        pattern: "solid",
        fgColor: {
          argb: "808080",
        },
      };
    cell.font = { color: { argb: "ffffff" }, bold: true };
    cell.border = {
      top: { style: "thin", color: { argb: "000000" } },
      left: { style: "thin", color: { argb: "000000" } },
      bottom: { style: "thin", color: { argb: "000000" } },
      right: { style: "thin", color: { argb: "000000" } },
    };
  });

  // });

  wb.xlsx.writeBuffer().then((buf) => {
    FileSaver.saveAs(new Blob([buf]), "Deseases" + `.xlsx`);
  });
};

export const exportExcel_version2 = async (workbookData, workbookname, filename = "") => {
  const wb = new ExcelJS.Workbook();
  if (!Array.isArray(workbookData.value)) {
    var workbooks = Array(workbookData.value);
  } else {
    var workbooks = workbookData.value;
  }
  var ws = wb.addWorksheet("Disease severity workbook");
  var cellHeaders = [
    "",
    "User name",
    "Field name",
    "Latitude and longitude",
    "Legal Land Description",
    "Municipality",
    "Date of risk assessment at flowering",
    "Rainfall in the last 14 days",
    "Likelihood of rain in 3-day weather forecast",
    "Plant density",
    "Years since last host crop",
    "Sclerotinia severity in last host crop",
    "Estimation of sclerotia germination",
    "Total risk points",
    "Fungicide recommendation",
    "No fungicide applied",
    "Date of disease severity assessment",
    "0. Number of plants with no symptoms",
    "1. Number of plants with superficial lesions",
    "2. Number of plants with 1-25% wilt",
    "3. Number of plants with 26-50% wilt",
    "4. Number of plants with 51-75% wilt",
    "5. Number of plants with 76-100% wilt",
    "Number of plants in the sample (optimal is 200, but can be any number)",
    "Disease severity index (DSI)",
    "fungicide applied",
    "Date of disease severity assessment",
    "0. Number of plants with no symptoms",
    "1. Number of plants with superficial lesions",
    "2. Number of plants with 1-25% wilt",
    "3. Number of plants with 26-50% wilt",
    "4. Number of plants with 51-75% wilt",
    "5. Number of plants with 76-100% wilt",
    "Number of plants in the sample (optimal is 200, but can be any number)",
    "Disease severity index (DSI)",
  ];

  for (let index = 1; index <= cellHeaders.length; index++) {
    ws.getRow(index + 1).getCell(2).value = cellHeaders[index];
    ws.getRow(index + 1).height = 30;
  }

  ws.mergeCells("A2:A6");
  ws.mergeCells("A7:A15");
  ws.mergeCells("A16:A25");
  ws.mergeCells("A26:A35");

  ws.getCell("A2").value = "Field location";
  ws.getCell("A7").value = "Step 1. Sclerotinia risk assessment at flowering";
  ws.getCell("A16").value = "Step 2. Disease severity assessment before harvest - no fungicide applied";
  ws.getCell("A26").value = "Step 2. Disease severity assessment before harvest - fungicide applied";

  ws.getCell("A2").alignment = {
    vertical: "middle",
    horizontal: "center",
    wrapText: true,
    textRotation: 90,
  };
  ws.getCell("A7").alignment = {
    vertical: "middle",
    horizontal: "center",
    wrapText: true,
    textRotation: 90,
  };
  ws.getCell("A16").alignment = {
    vertical: "middle",
    horizontal: "center",
    wrapText: true,
    textRotation: 90,
  };

  ws.getCell("A26").alignment = {
    vertical: "middle",
    horizontal: "center",
    wrapText: true,
    textRotation: 90,
  };

  ws.getColumn(2).alignment = {
    vertical: "middle",
    horizontal: "left",
    wrapText: true,
  };
  ws.getColumn(2).width = 40;
  var i = 0;
  var j = 3;
  workbooks.map((v) => {
    
    if(v.hasOwnProperty("riskform_id")){

      ws.getRow(2).getCell(j).value = v.username;
      ws.getRow(3).getCell(j).value = v.fieldName;
      if (v.latitude || v.latitude) {
        ws.getRow(4).getCell(j).value = v.latitude + " " + v.longitude;
      } else {
        ws.getRow(4).getCell(j).value = "";
      }
      ws.getRow(5).getCell(j).value = v.legalLand;
      ws.getRow(6).getCell(j).value = v.ruralMunicipality;

      ws.getRow(7).getCell(j).value = moment(v.date).format("L");
      ws.getRow(8).getCell(j).value = v.rainfall;
      ws.getRow(9).getCell(j).value = v.likelyhood;
      ws.getRow(10).getCell(j).value = v.density;
      ws.getRow(11).getCell(j).value = v.likelyhood;
      ws.getRow(12).getCell(j).value = v.severity;
      ws.getRow(13).getCell(j).value = v.estimation;
      ws.getRow(14).getCell(j).value = (parseInt(v.rainfall) + parseInt(v.likelyhood) + parseInt(v.density) + parseInt(v.likelyhood) + parseInt(v.severity) + parseInt(v.estimation));
      ws.getRow(15).getCell(j).value = v.recomm;


      ws.getRow(16).getCell(j).value = "No Fungicide";
      ws.getRow(17).getCell(j).value = v.workbook_date;
      ws.getRow(18).getCell(j).value = v.symptoms;
      ws.getRow(19).getCell(j).value = v.lesions;
      ws.getRow(20).getCell(j).value = v.wilt25;
      ws.getRow(21).getCell(j).value = v.wilt50;
      ws.getRow(22).getCell(j).value = v.wilt75;
      ws.getRow(23).getCell(j).value = v.wilt100;
      ws.getRow(24).getCell(j).value = v.plants;
      ws.getRow(25).getCell(j).value = v.dsi;

      ws.getRow(26).getCell(j).value = "Fungicide Applied";
      ws.getRow(27).getCell(j).value = v.s_date;
      ws.getRow(28).getCell(j).value = v.s_symptoms;
      ws.getRow(29).getCell(j).value = v.s_lesions;
      ws.getRow(30).getCell(j).value = v.s_wilt25;
      ws.getRow(31).getCell(j).value = v.s_wilt50;
      ws.getRow(32).getCell(j).value = v.s_wilt75;
      ws.getRow(33).getCell(j).value = v.s_wilt100;
      ws.getRow(34).getCell(j).value = v.s_plants;
      ws.getRow(35).getCell(j).value = v.s_dsi;
      
    }else{

      ws.getRow(2).getCell(j).value = v.firstName;
      ws.getRow(3).getCell(j).value = v.fieldName;
      if (v.location) {
        ws.getRow(4).getCell(j).value = v.location;
      } else {
        ws.getRow(4).getCell(j).value = "";
      }
      ws.getRow(5).getCell(j).value = v.legalLand;
      ws.getRow(6).getCell(j).value = v.ruralMunicipality;

      ws.getRow(7).getCell(j).value = moment(v.date).format("L");
      ws.getRow(8).getCell(j).value = v.rainfall.value;
      ws.getRow(9).getCell(j).value = v.likelyhoodOfRain.value;
      ws.getRow(10).getCell(j).value = v.plantDensity.value;
      ws.getRow(11).getCell(j).value = v.hostCropYear.value;
      ws.getRow(12).getCell(j).value = v.hostCropSeverarity.value;
      ws.getRow(13).getCell(j).value = v.sclerotiniaGermination.value;
      ws.getRow(14).getCell(j).value = (parseInt(v.rainfall.value) + parseInt(v.likelyhoodOfRain.value) + parseInt(v.plantDensity.value) + parseInt(v.hostCropYear.value) + parseInt(v.hostCropSeverarity.value) + parseInt(v.sclerotiniaGermination.value));
      ws.getRow(15).getCell(j).value = v.rec;
    }
    

    ws.getColumn(j).width = 20;
    ws.getColumn(j).alignment = {
      vertical: "middle",
      horizontal: "left",
      wrapText: true,
    };
    ws.getColumn(j + 1).width = 5;
    ws.getColumn(j + 1).alignment = {
      vertical: "middle",
      horizontal: "left",
      wrapText: true,
    };
    i = i+1;
    j = j+2;

  });
  ws.getColumn(1).eachCell(function (cell, colNumber) {
    if (cell.value)
      cell.fill = {
        type: "pattern",
        pattern: "solid",
        fgColor: {
          argb: "808080",
        },
      };
    cell.font = { color: { argb: "ffffff" }, bold: true };
    cell.border = {
      top: { style: "thin", color: { argb: "000000" } },
      left: { style: "thin", color: { argb: "000000" } },
      bottom: { style: "thin", color: { argb: "000000" } },
      right: { style: "thin", color: { argb: "000000" } },
    };
  });
  ws.getColumn(2).eachCell(function (cell, colNumber) {
    if (cell.value)
      cell.fill = {
        type: "pattern",
        pattern: "solid",
        fgColor: {
          argb: "808080",
        },
      };
    cell.font = { color: { argb: "ffffff" }, bold: true };
    cell.border = {
      top: { style: "thin", color: { argb: "000000" } },
      left: { style: "thin", color: { argb: "000000" } },
      bottom: { style: "thin", color: { argb: "000000" } },
      right: { style: "thin", color: { argb: "000000" } },
    };
  });

  if (!filename) {
    filename = workbooks[0].fieldName;
  }
  wb.xlsx.writeBuffer().then((buf) => {
    FileSaver.saveAs(new Blob([buf]), filename + `.xlsx`);
  });
};

export const exportExcel = async (workbookData, workbookname, filename = "") => {
  const wb = new ExcelJS.Workbook();
  if (!Array.isArray(workbookData.value)) {
    var workbooks = Array(workbookData.value);
  } else {
    var workbooks = workbookData.value;
  }
  var ws = wb.addWorksheet("Disease severity workbook");
  var cellHeaders = [
    "",
    "User name",
    "Field name",
    "Latitude and longitude",
    "Legal Land Description",
    "Rural municipality/County",
    "Date of risk assessment at flowering",
    "Rainfall in the last 14 days",
    "Likelihood of rain in 3-day weather forecast",
    "Plant density",
    "Years since last host crop",
    "Sclerotinia severity in last host crop",
    "Estimation of sclerotia germination",
    "Total risk points",
    "Fungicide recommendation",
    "Date of severity assessment",
    "No fungicide applied",
    "0. Number of plants with no symptoms",
    "1. Number of plants with superficial lesions",
    "2. Number of plants with 1-25% wilt",
    "3. Number of plants with 26-50% wilt",
    "4. Number of plants with 51-75% wilt",
    "5. Number of plants with 76-100% wilt",
    "Number of plants in the sample (optimal is 200, but can be any number)",
    "Disease severity index (DSI)",
    "fungicide applied",
    "0. Number of plants with no symptoms",
    "1. Number of plants with superficial lesions",
    "2. Number of plants with 1-25% wilt",
    "3. Number of plants with 26-50% wilt",
    "4. Number of plants with 51-75% wilt",
    "5. Number of plants with 76-100% wilt",
    "Number of plants in the sample (optimal is 200, but can be any number)",
    "Disease severity index (DSI)",
  ];

  for (let index = 1; index <= cellHeaders.length; index++) {
    ws.getRow(index + 1).getCell(2).value = cellHeaders[index];
    ws.getRow(index + 1).height = 30;
  }

  ws.mergeCells("A2:A6");
  ws.mergeCells("A7:A15");
  ws.mergeCells("A17:A25");
  ws.mergeCells("A26:A34");

  ws.getCell("A2").value = "Field location";
  ws.getCell("A7").value = "Step 1. Sclerotinia risk assessment at flowering";
  ws.getCell("A16").value = " ";
  ws.getCell("A17").value = "Step 2. Disease severity assessment before harvest - no fungicide applied";
  ws.getCell("A26").value = "Step 2. Disease severity assessment before harvest - fungicide applied";

  ws.getCell("A2").alignment = {
    vertical: "middle",
    horizontal: "center",
    wrapText: true,
    textRotation: 90,
  };
  ws.getCell("A7").alignment = {
    vertical: "middle",
    horizontal: "center",
    wrapText: true,
    textRotation: 90,
  };
  ws.getCell("A16").alignment = {
    vertical: "middle",
    horizontal: "center",
    wrapText: true,
    textRotation: 90,
  };
  ws.getCell("A17").alignment = {
    vertical: "middle",
    horizontal: "center",
    wrapText: true,
    textRotation: 90,
  };

  ws.getCell("A26").alignment = {
    vertical: "middle",
    horizontal: "center",
    wrapText: true,
    textRotation: 90,
  };

  ws.getColumn(2).alignment = {
    vertical: "middle",
    horizontal: "left",
    wrapText: true,
  };
  ws.getColumn(2).width = 40;
  var i = 0;
  var j = 3;
  workbooks.map((v) => {
    
    if(v.hasOwnProperty("riskform_id")){

      ws.getRow(2).getCell(j).value = v.username;
      ws.getRow(3).getCell(j).value = v.fieldName;
      if (v.latitude || v.latitude) {
        ws.getRow(4).getCell(j).value = v.latitude + " " + v.longitude;
      } else {
        ws.getRow(4).getCell(j).value = "";
      }
      ws.getRow(5).getCell(j).value = v.legalLand;
      ws.getRow(6).getCell(j).value = v.ruralMunicipality;

      ws.getRow(7).getCell(j).value = moment(v.date).format("L");
      ws.getRow(8).getCell(j).value = v.rainfall;
      ws.getRow(9).getCell(j).value = v.likelyhood;
      ws.getRow(10).getCell(j).value = v.density;
      ws.getRow(11).getCell(j).value = v.likelyhood;
      ws.getRow(12).getCell(j).value = v.severity;
      ws.getRow(13).getCell(j).value = v.estimation;
      ws.getRow(14).getCell(j).value = (parseInt(v.rainfall) + parseInt(v.likelyhood) + parseInt(v.density) + parseInt(v.likelyhood) + parseInt(v.severity) + parseInt(v.estimation));
      ws.getRow(15).getCell(j).value = v.recomm;

      ws.getRow(16).getCell(j).value = v.workbook_date;

      ws.getRow(17).getCell(j).value = "No Fungicide";
      ws.getRow(18).getCell(j).value = v.symptoms;
      ws.getRow(19).getCell(j).value = v.lesions;
      ws.getRow(20).getCell(j).value = v.wilt25;
      ws.getRow(21).getCell(j).value = v.wilt50;
      ws.getRow(22).getCell(j).value = v.wilt75;
      ws.getRow(23).getCell(j).value = v.wilt100;
      ws.getRow(24).getCell(j).value = v.plants;
      ws.getRow(25).getCell(j).value = v.dsi;

      ws.getRow(26).getCell(j).value = "Fungicide Applied";
      ws.getRow(27).getCell(j).value = v.s_symptoms;
      ws.getRow(28).getCell(j).value = v.s_lesions;
      ws.getRow(29).getCell(j).value = v.s_wilt25;
      ws.getRow(30).getCell(j).value = v.s_wilt50;
      ws.getRow(31).getCell(j).value = v.s_wilt75;
      ws.getRow(32).getCell(j).value = v.s_wilt100;
      ws.getRow(33).getCell(j).value = v.s_plants;
      ws.getRow(34).getCell(j).value = v.s_dsi;
      
    }else{

      ws.getRow(2).getCell(j).value = v.firstName +" "+ v.lastName;
      ws.getRow(3).getCell(j).value = v.fieldName;
      if (v.location) {
        ws.getRow(4).getCell(j).value = v.location;
      } else {
        ws.getRow(4).getCell(j).value = "";
      }
      ws.getRow(5).getCell(j).value = v.legalLand;
      ws.getRow(6).getCell(j).value = v.ruralMunicipality;

      ws.getRow(7).getCell(j).value = moment(v.date).format("L");
      ws.getRow(8).getCell(j).value = v.rainfall.value;
      ws.getRow(9).getCell(j).value = v.likelyhoodOfRain.value;
      ws.getRow(10).getCell(j).value = v.plantDensity.value;
      ws.getRow(11).getCell(j).value = v.hostCropYear.value;
      ws.getRow(12).getCell(j).value = v.hostCropSeverarity.value;
      ws.getRow(13).getCell(j).value = v.sclerotiniaGermination.value;
      ws.getRow(14).getCell(j).value = (parseInt(v.rainfall.value) + parseInt(v.likelyhoodOfRain.value) + parseInt(v.plantDensity.value) + parseInt(v.hostCropYear.value) + parseInt(v.hostCropSeverarity.value) + parseInt(v.sclerotiniaGermination.value));
      ws.getRow(15).getCell(j).value = v.rec;
    }
    

    ws.getColumn(j).width = 20;
    ws.getColumn(j).alignment = {
      vertical: "middle",
      horizontal: "left",
      wrapText: true,
    };
    ws.getColumn(j + 1).width = 5;
    ws.getColumn(j + 1).alignment = {
      vertical: "middle",
      horizontal: "left",
      wrapText: true,
    };
    i = i+1;
    j = j+2;

  });
  ws.getColumn(1).eachCell(function (cell, colNumber) {
    if (cell.value)
      cell.fill = {
        type: "pattern",
        pattern: "solid",
        fgColor: {
          argb: "808080",
        },
      };
    cell.font = { color: { argb: "ffffff" }, bold: true };
    cell.border = {
      top: { style: "thin", color: { argb: "000000" } },
      left: { style: "thin", color: { argb: "000000" } },
      bottom: { style: "thin", color: { argb: "000000" } },
      right: { style: "thin", color: { argb: "000000" } },
    };
  });
  ws.getColumn(2).eachCell(function (cell, colNumber) {
    if (cell.value)
      cell.fill = {
        type: "pattern",
        pattern: "solid",
        fgColor: {
          argb: "808080",
        },
      };
    cell.font = { color: { argb: "ffffff" }, bold: true };
    cell.border = {
      top: { style: "thin", color: { argb: "000000" } },
      left: { style: "thin", color: { argb: "000000" } },
      bottom: { style: "thin", color: { argb: "000000" } },
      right: { style: "thin", color: { argb: "000000" } },
    };
  });

  if (!filename) {
    filename = workbooks[0].fieldName;
  }
  wb.xlsx.writeBuffer().then((buf) => {
    FileSaver.saveAs(new Blob([buf]), filename + `.xlsx`);
  });
};

export default exportExcel;

