import { combineReducers } from "redux";
import locationReducer from "./location";
import requiredField from "./requiredField";
import risk from "./risk";
import workbookReducer from "./workbook ";
import disableReducer from "./disable";
import auth from "./auth";
export default combineReducers({
  location: locationReducer,
  workbook: workbookReducer,
  risk: risk,
  required: requiredField,
  disable: disableReducer,
  auth: auth,
});
