export const initGTM = (measurementId) => {
  console.log('Initializing Google Analytics with ID:', measurementId);

  // Ensure dataLayer is defined
  window.dataLayer = window.dataLayer || [];

  // Define gtag function
  function gtag() {
    window.dataLayer.push(arguments);
  }

  window.gtag = gtag;

  // Load the Google Analytics script
  const script = document.createElement('script');
  script.async = true;
  script.src = `https://www.googletagmanager.com/gtag/js?id=${measurementId}`;
  script.onload = () => {
    console.log('Google Analytics script loaded');

    // Initialize gtag
    gtag('js', new Date());
    gtag('config', measurementId);
  };
  script.onerror = () => {
    console.error('Error loading Google Analytics script');
  };

  document.head.appendChild(script);
};
