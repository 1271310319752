import moment from "moment";
import React, { useCallback, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import exportExcel from "../../DiseaseSeverity/utils/exportExcel";
import {
  getDATApi,
  getPOSTapi,
} from "../../SclerotiniaRiskAssessment/utils/email";

import { Box, TablePagination } from "@mui/material";

import swal from "sweetalert";

const Combinedreport = () => {
  const [data, setdata] = useState([]);
  const [Checked, setChecked] = useState([]);
  const [RenameRow, setRenameRow] = useState([]);
  const [RenameRowType, setRenameRowType] = useState([]);
  const [RenameValue, setRenameValue] = useState("");
  const { auth } = useSelector((state) => state.auth);
  const [rowsPerPage, setRowsPerPage] = useState(20);
  const [page, setPage] = useState(0);

  const reportlisting = useCallback(async () => {
    const data = await getDATApi(`admin/allListing`);
    if (data.status) {
      setdata(
        data.result.sort((a, b) => {
          return new Date(b.createdDate) - new Date(a.createdDate);
        })
      );
    }
  }, []);

  useEffect(() => {
    reportlisting();
  }, []);

  const handleChange = (e) => {
    let Ids = [...Checked];
    let value = e.target.value;
    if (Ids.includes(value)) {
      let Index = Ids.indexOf(value);
      Ids.splice(Index, 1);
    } else {
      Ids.push(value);
    }
    setChecked(Ids);
  };

  // const AllCheck = (e) => {
  //   let Ids = [...Checked];

  //   if (!e.target.checked) {
  //     Ids = [];
  //   } else {
  //     data.map((value) => Ids.includes(value._id) || Ids.push(value._id));
  //   }

  //   setChecked(Ids);
  // };

  const AllCheck = (e) => {
    let Ids = [...Checked];

    if (!e.target.checked) {
      Ids = [];
    } else {
      data.map((value) => Ids.includes(value._id) || Ids.push(value._id));
    }

    setChecked(Ids);
  };

  const Download = () => {
    let Ids = [...Checked];
    let csvData = data.filter((value) => Ids.includes(value._id));
    exportExcel({ value: csvData, ...auth }, "");
  };

  const RenameFile = (value, stepType) => {
    if (RenameRow.includes(value._id)) {
      setRenameRowType([stepType]);
      submitRename();

    } else {
      setRenameRow([value._id]);
      setRenameRowType([stepType]);
    }
    setRenameValue(value.fieldName);
  };

  const submitRename = async () => {
    if (RenameRow && RenameRow.length > 0) {
    
      const stepTypeValue = (RenameRowType[0] === "workbook_report") ? "admin/workbook-update/": "admin/risk-update/";
      const res = await getPOSTapi(stepTypeValue + RenameRow[0], {
        fieldName: RenameValue,
      });
      
      if (res.status) {
        setRenameRow([]);
        setRenameRowType([]);
        reportlisting();
      }
    }
  };

  const removeRecord = (id,reportTypeval) => {
    if (id) {
      swal({
        title: "Are you sure?",
        text: "Once deleted, you will not be able to recover this!",
        icon: "warning",
        buttons: true,
        dangerMode: true,
      }).then(async (willDelete) => {
        if (willDelete) {
          const reportTypeValue = (reportTypeval === "workbook_report") ? "admin/workbook-remove/": "admin/risk-remove/";
          const res = await getPOSTapi(reportTypeValue + id, {});
          if (res.status) {
            setRenameRow([]);
            setRenameRowType([]);
            reportlisting();
          }
          swal("Poof! Your file has been deleted!", {
            icon: "success",
          });
        } else {
          swal("Your file is safe!");
        }
      });
    }
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  return (
    <>
      <table class="table">
        <thead>
          <tr>
            <th
              width="8%"
              className="text-center"
              scope="col"
              onChange={AllCheck}
            >
              <input type="checkbox" id="all" name="" />
            </th>
            <th scope="col">Field Name</th>
            <th scope="col">Date of Step 1 (Risk Assesment)</th>
            <th scope="col">Date of Step 2 (Severity Assesment)</th>
            <th scope="col">User Name</th>
            <th scope="col">&nbsp;</th>
          </tr>
        </thead>
        <tbody>
          
          {data.length > 0 ? (
            data.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((value) => {
              let reportType = value.riskform_id !== undefined ? "workbook_report" : "risk_report";
              return (<tr key={value._id}>
                <td data-label="" className="text-center" onChange={handleChange}>
                  <input
                    checked={Checked.includes(value._id)}
                    type="checkbox"
                    id=""
                    value={value._id}
                    name=""
                  />
                </td>
                <td data-label="File Name">
                  {RenameRow.includes(value._id) ? (
                    <>
                      <input
                        type="text"
                        onBlur={submitRename}
                        onKeyDown={(event) => {
                          if (event.key == "Enter") {
                            submitRename(reportType);
                          }
                        }}
                        value={RenameValue}
                        className="form-control form-control-sm"
                        onChange={(e) => {
                          setRenameValue(e.target.value);
                        }}
                      />
                    </>
                  ) : (
                    `${value.fieldName}`
                  )}
                </td>
                <td data-label="Date of step 1">
                  {value.workbook_date || value.s_date || value.date
                    ? moment(value.date ?? value.s_date ?? value.workbook_date).format(
                        "MMM D, YYYY"
                      )
                    : ""}
                </td>
                <td data-label="Date of step 2" align="right" class="loadData">
                  {
                    !value.riskform_id ? "--" : 
                    (value.date ? moment(value.workbook_date ?? value.s_date).format("MMM D, YYYY") : "")
                  }
                </td>
                <td data-label="" align="right" class="loadData">
                  {value.riskform_id ? value.username : value.firstName + value.lastName}
                </td>
                
                <td align="right" class="actionBtn">
                  {/* <button
                    onClick={(e) => {
                      e.preventDefault();
                      removeRecord(value._id,reportType);
                    }}
                    className="btn btn-md"
                  >
                    <i class="far fa-trash"></i>
                  </button> */}
                  <button
                    className="btn btn-md"
                    onClick={(e) => {
                      e.preventDefault();
                      exportExcel({ value, ...auth },"");
                    }}
                  >
                    <i class="fas fa-download"></i>
                  </button>
                </td>
              </tr>
              )
            })
          ) : (
            <tr>
              <td colSpan={5}>
                <h5 className="text-center">No saved Reports</h5>
              </td>
            </tr>
          )}
        </tbody>
        <tfoot>
          <tr>
            <td colSpan={5}>
              <button
                className="btn btn-sm btn-primary"
                onClick={(e) => {
                  e.preventDefault();
                  Download();
                }}
                disabled={Checked.length < 1}
              >
                Download
              </button>
            </td>
          </tr>
        </tfoot>
      </table>

      <TablePagination
      rowsPerPageOptions={[10, 20, 50]}
      component={Box}
      count={data.length}
      rowsPerPage={rowsPerPage}
      page={page}
      onPageChange={handleChangePage}
      onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </>
  );
};

export default Combinedreport;

