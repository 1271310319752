import { ADD_RISK, DELETE_RISK, UPDATE_RISK } from "../Components/actions/actionTypes";
import moment from "moment";
const initialState=[
    
    ["use1",{id:1,
    risk:{
        username:"",
        fieldname:"",
        town:"",
        province:"",
        latitude:"",
        longitude:"",
        date:null,
        rainfall:"",
        likelyhood:"",
        density:"",
        lasthost:"",
        estimation:"",
        severity:'',
        total:"",
        recomm:""
    }
    }],[

    "use2",{id:2,
    risk:{
        usename:"",
        fieldname:"",
        town:"",
        province:"",
        latitude:"",
        longitude:"",
        date:null,
        rainfall:"",
        likelyhood:"",
        density:"",
        lasthost:"",
        severity:'',
        estimation:"",
        total:"",
        recomm:""
    }
    }],
    [

    "use3",{id:3,
    risk:{
        username:"",
        fieldname:"",
        town:"",
        province:"",
        latitude:"",
        longitude:"",
        date:null ,
        rainfall:"",
        likelyhood:"",
        density:"",
        lasthost:"",
        severity:'',
        estimation:"",
        total:"",
        recomm:""
    }
    }],
[
    "use4",{id:4,
    risk:{
        username:"",
        fieldname:"",
        town:"",
        province:"",
        latitude:"",
        longitude:"",
        date:null ,
        rainfall:"",
        likelyhood:"",
        density:"",
        lasthost:"",
        severity:'',
        estimation:"",
        total:"",
        recomm:""
    }
    }],

    ["use5",{id:5,
    risk:{
        username:"",
        fieldname:"",
        town:"",
        province:"",
        latitude:"",
        longitude:"",
        date:null ,
        rainfall:"",
        likelyhood:"",
        density:"",
        lasthost:"",
        severity:'',
        estimation:"",
        total:"",
        recomm:""
    }
    }
]]
export default function (state = initialState, action) {
    const { payload } = action
    let id;
    if (payload){
        id=payload.id
    }
    switch (action.type) {
        case ADD_RISK:
            return state.map(entry=>{
                if(entry[0]===id){
                    entry[1]=payload.data
                }
                return entry
            })
        case UPDATE_RISK:
            const field=payload.data.field
            return state.map(entry=>{
                if(entry[0]===id){
                    if(field=="username"){
                        entry[1].risk.username=payload.data.value
                    }
                    if(field=="fieldname"){
                        entry[1].risk.fieldname=payload.data.value
                    }
                    else
                    if(field=="location"){
                        entry[1].risk.latitude=payload.data.value.latitude
                        entry[1].risk.longitude=payload.data.value.longitude
                    }
                    else if(field=="date"){
                        entry[1].risk.date=moment(payload.data.value).format("MM/DD/YY")  //payload.data.value
                    }
                    else if(field=="rainfall"){
                        entry[1].risk.rainfall=payload.data.value
                    }
                    else if(field=="likelyhood"){
                        entry[1].risk.likelyhood=payload.data.value
                    }
                    else if(field=="density"){
                        entry[1].risk.density=payload.data.value
                    }
                    else if(field=="lasthost"){
                        entry[1].risk.lasthost=payload.data.value
                    }
                    else if(field=="severity"){
                        entry[1].risk.severity=payload.data.value
                    }
                    else if(field=="estimation"){
                        entry[1].risk.estimation=payload.data.value
                    }
                    else if(field=="total"){
                        entry[1].risk.total=payload.data.value
                    }
                    else if(field=="recomm"){
                        entry[1].risk.recomm=payload.data.value
                    }
                    else if(field=="town"){
                        entry[1].risk.town=payload.data.value
                    }
                    else if(field=="province"){
                        entry[1].risk.province=payload.data.value
                    }
                    //entry[1].risk.fieldname=payload.data.value
                    return entry
                }
                return entry
            })
        case DELETE_RISK:
            return state.map((entry,index)=>{
                if(entry[0]==id){
                    return [id,{id:1,
                        risk:{
                            username:"",
                            fieldname:"",
                            town:"",
                            province:"",
                            latitude:"",
                            longitude:"",
                            date:null ,
                            rainfall:"",
                            likelyhood:"",
                            density:"",
                            lasthost:"",
                            estimation:"",
                            severity:'',
                            total:"",
                            recomm:""
                        }
                        }]
                } 
                return entry
            })
        default:
            return state
    }
}
