import {
  ADD_RISK,
  DELETE_RISK,
  DELETE_WORKBOOK,
  SET_DISABLE,
  SET_ENABLE,
  UPDATE_RISK,
  UPDATE_WORKBOOK,
  AUTH,
  LOGOUT_AUTH,
  ADMIN_AUTH,
  ADMIN_LOGOUT_AUTH,
} from "./actionTypes";
import moment from "moment";

export const addRisk = (dispatch, data, id) => {
  // const payloaddata = {
  //   id: id,
  //   risk: {
  //     fieldname: data[0],
  //     username: data[1] + " " + data[2],
  //     latitude: data[3].split(" ")[0],
  //     longitude: data[3].split(" ")[1],
  //     location: data[3],
  //     date:
  //       typeof data[4] == "number"
  //         ? moment(new Date((data[4] - (25568 + 1)) * 86400 * 1000)).format(
  //             "MM/DD/YY"
  //           )
  //         : moment(data[4]).format("MM/DD/YY"),
  //     rainfall: data[6],
  //     likelyhood: data[8],
  //     density: data[10],
  //     lasthost: data[12],
  //     severity: data[14],
  //     estimation: data[16],
  //     total: data[17],
  //     recomm: data[18],
  //   },
  // };
  const payloaddata = {
    id: id,
    risk: {
      ...data,
    },
  };
  dispatch({
    type: ADD_RISK,
    payload: {
      id: `use${id}`,
      data: payloaddata,
    },
  });
};
export const deleteRisk = (id) => (dispatch) => {
  dispatch({
    type: DELETE_RISK,
    payload: {
      id: `use${id}`,
    },
  });
};

export const updateRisk = (value, id, field) => (dispatch) => {
  dispatch({
    type: UPDATE_RISK,
    payload: {
      id: `use${id}`,
      data: {
        value,
        id,
        field,
      },
    },
  });
};

export const setDisable = (id) => (dispatch) => {
  dispatch({
    type: SET_DISABLE,
    payload: {
      id: `use${id}`,
    },
  });
};

export const setEnable = (id) => (dispatch) => {
  dispatch({
    type: SET_ENABLE,
    payload: {
      id: `use${id}`,
    },
  });
};

export const updateWorkbook = (value, id, field) => (dispatch) => {
  dispatch({
    type: UPDATE_WORKBOOK,
    payload: {
      id: `use${id}`,
      data: {
        value,
        id,
        field,
      },
    },
  });
};
export const deleteWorkbook = (id) => (dispatch) => {
  dispatch({
    type: DELETE_WORKBOOK,
    payload: {
      id: `use${id}`,
    },
  });
};

export const AuthLogin = (value) => (dispatch) => {
  dispatch({
    type: AUTH,
    payload: value,
  });
};

export const AuthLogOut = (value) => (dispatch) => {
  dispatch({
    type: LOGOUT_AUTH,
    payload: value,
  });
};

export const AdminAuthLogin = (value) => (dispatch) => {
  dispatch({
    type: ADMIN_AUTH,
    payload: value,
  });
};

export const AdminAuthLogOut = (value) => (dispatch) => {
  dispatch({
    type: ADMIN_LOGOUT_AUTH,
    payload: value,
  });
};
