import {RESET_LOCATION, SET_LOCATION} from './../Components/actions/actionTypes'
export default function(state=[],action){
    switch(action.type){
        case SET_LOCATION:
            return {
                ...state,
                coordinates:action.payload
            }
        case RESET_LOCATION:
            return {
                
            }
        default : return state
    }
}