export const SET_LOCATION = "SET_LOCATION";
export const RESET_LOCATION = "RESET_LOCATION";
export const ADD_RISK = "ADD_RISK";
export const UPDATE_RISK = "UPDATE_RISK";
export const UPDATE_WORKBOOK = "UPDATE_WORKBOOK";
export const DELETE_RISK = "DELETE_RISK";
export const DELETE_WORKBOOK = "DELETE_WORKBOOK";
export const SET_REQUIRED = "SET_REQUIRED";
export const CLEAR_REQUIRED = "CLEAR_REQUIRED";
export const AUTH = "AUTH";
export const LOGOUT_AUTH = "LOGOUT_AUTH";
export const SET_DISABLE = "SET_DISABLE";
export const SET_ENABLE = "SET_ENABLE";

export const ADMIN_AUTH = "ADMIN_AUTH";
export const ADMIN_LOGOUT_AUTH = "ADMIN_LOGOUT_AUTH";
