import React, { lazy, Suspense, useEffect } from "react";
import { BrowserRouter, Route, Switch, useLocation } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import Menu from "./Menu";
//import SclerotiniaRiskAssessmentForm from "./SclerotiniaRiskAssessment/SclerotiniaRiskAssessmentForm";
// import { EconomicCalculator } from "./EconomicCalculator/EconomicCalculator";
// import DiseaseServerity from "./DiseaseSeverity/DiseaseServerity";
// import SclerotiniaRating from "./SclerotiniaRating";
// import YoutubeVideo from "./YoutubeVideo";
// import PhotoGallery from "./PhotoGallery";
// import { Login } from "./admin/Login";
// import { ProtectedDashboard } from "./admin/ProtectedDashboard";
import "bootstrap/dist/css/bootstrap.min.css";
import "react-toastify/dist/ReactToastify.css";
import Header from "./Header";
import Footer from "./Footer";
import MyLoader from "./MyLoader";
import LoginForm from "./User/LoginForm";
import SignupForm from "./User/SignupForm";
import Profile from "./User/Profile";
import ChangePassword from "./User/ChangePassword";
import ForgotPassword from "./User/ForgotPassword";
import Report from "./Report/Report";

import AdminReport from "./admin/Report/Report";
import AllUsers from "./admin/User/User";
import Dashboard from "./admin/Dashboard";
import { useSelector } from "react-redux";
import { initGTM } from "../gtag";


const SclerotiniaRiskAssessmentForm = lazy(() =>
  import("./SclerotiniaRiskAssessment/SclerotiniaRiskAssessmentForm")
);
const DiseaseServerity = lazy(() =>
  import("./DiseaseSeverity/DiseaseServerity")
);
const EconomicCalculator = lazy(() =>
  import("./EconomicCalculator/EconomicCalculator")
);
const SclerotiniaRating = lazy(() => import("./SclerotiniaRating"));
const Depot = lazy(() => import("./Depot"));
const YoutubeVideo = lazy(() => import("./YoutubeVideo"));
const PhotoGallery = lazy(() => import("./PhotoGallery"));
const Login = lazy(() => import("./admin/Login"));
const ProtectedDashboard = lazy(() => import("./admin/ProtectedDashboard"));
const NotFound = () => <h1>Error 404 No Component Found</h1>;

const RouteChangeTracker = ({ onRouteChange }) => {
  const location = useLocation();

  useEffect(() => {
    onRouteChange(location);
  }, [location, onRouteChange]);

  return null;
};

const AppRouter = () => {

  const { admin_auth } = useSelector((state) => state.auth.admin_auth);

  initGTM('G-DT6FZV445B');
  // useEffect(() => {
  // }, []);

  const handleRouteChange = (location) => {
    window.dataLayer.push({
      event: 'page_view',
      page: {
        path: location.pathname + location.search,
        title: document.title,
      },
    });
    console.log('Page view tracked:', location.pathname + location.search);

  };

  return (
    <div>
      <BrowserRouter basename="sclerotinia-calc">
        <ToastContainer position="bottom-left" closeOnClick />
        <Header />
        <Suspense fallback={<MyLoader />}>
          <RouteChangeTracker onRouteChange={handleRouteChange} />

          <Switch>
            <Route path="/" component={Menu} exact />
            <Route path="/menu" component={Menu} />
            <Route
              path="/sclerotiniariskassessment"
              component={SclerotiniaRiskAssessmentForm}
              exact={true}
            />
            <Route path="/reports" component={Report} exact={true} />
            <Route path="/profile" component={Profile} exact={true} />
            <Route
              path="/changePassword"
              component={ChangePassword}
              exact={true}
            />
            <Route
              path="/forgetPassword"
              component={ForgotPassword}
              exact={true}
            />
            <Route
              exact
              path="/economic-calculator"
              component={EconomicCalculator}
            />
            <Route
              exact
              path="/disease-severity-workbook/:id?"
              component={DiseaseServerity}
            />
            <Route
              exact
              path="/sclerotinia-rating-scale"
              component={SclerotiniaRating}
            />
            <Route
              exact
              path="/how-to-make-sclerotia-depot"
              component={Depot}
            />
            <Route exact path="/signup" component={SignupForm} />
            <Route exact path="/login" component={LoginForm} />
            <Route exact path="/youtube-video" component={YoutubeVideo} />
            <Route exact path="/photo-gallery" component={PhotoGallery} />
            <Route exact path="/admin/login" component={Login} />


            <Route exact path="/admin/reports" component={AdminReport} />
            <Route exact path="/admin/users" component={AllUsers} />
            <Route exact path="/admin/dash" component={Dashboard} />


            <Route exact path="/admin/dashboard">
              <ProtectedDashboard />
            </Route>
            <Route component={NotFound}></Route>
          </Switch>
        </Suspense>
        <Footer />
      </BrowserRouter>
    </div>
  );
};
export default AppRouter;
