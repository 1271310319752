import React, { useState, useEffect } from "react";
import { useHistory } from "react-router";
import { Link } from "react-router-dom";
// import '../../styles/new_css/bootstrap.min.css'
const ForgotPassword = () => {
  const [email, setEmail] = useState("");
  const history = useHistory();

  useEffect(() => {
    if (localStorage.getItem("token")) {
      history.push("/");
    }
  }, []);

  async function forgotPassword() {
    // email = email.toLowerCase();
    let item = {
      email: email,
    };
    let result = await fetch(
      process.env.REACT_APP_BACKEND_URL + "/user/forgotPassword",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "applicaton/json",
        },
        body: JSON.stringify(item),
      }
    );
    result = await result.json();
    if (result.status == "true") {
      alert(result.message);
      history.push("/");
      window.location.reload();
    } else {
      alert(result.message);
    }
  }

  return (
    <section className="profile">
      <div className="container align-items-center">
        <div className="row justify-content-center no-gutters align-items-center">
          <div className="col-md-7 col-lg-5">
            <div className="loginBox m-auto">
              <h1>
                <span>Forgot Password</span>
              </h1>
              <form className="form-horizontal" action="" method="post">
                <fieldset>
                  <div className="form-group position-relative">
                    <div className="iconImg">
                      <img src="img/email.png" />
                    </div>
                    <input
                      id="email"
                      name="email"
                      type="text"
                      placeholder="Enter email"
                      className="form-control"
                      onChange={(e) => setEmail(e.target.value)}
                    />
                  </div>

                  <div className="form-group mb-0 d-flex justify-content-between align-items-center subBtn">
                    <button
                      type="submit"
                      onClick={(e) => {
                        e.preventDefault();
                        forgotPassword();
                      }}
                    >
                      Submit
                    </button>
                  </div>
                </fieldset>
              </form>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ForgotPassword;
